import React, { useEffect, useState } from "react";
import { FormNext, FormDown } from "grommet-icons";
import axios from "axios";
import {isMobile} from 'react-device-detect';
import moment from "moment";
import SubNavBack from "./subNavBack";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { AiFillMail } from "react-icons/ai";
import { AiTwotoneCarryOut } from "react-icons/ai";
import { AiFillPlayCircle } from "react-icons/ai";
import { AiOutlineMore } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { Modal, Button, Image } from "react-bootstrap";

import "bootstrap/dist/js/bootstrap.js";
import "react-datepicker/dist/react-datepicker.css";
import SelectListStore from "./selectListStore";
import SelectListJobs from "./selectListJobs";
import SelectListDispo from "./selectListDispo";
import SelectListSports from "./selectListSports";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "moment/locale/es";
import SelectListCalendar from "./selectListCalendar";
import { Loader } from "../../Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { LoaderDel } from "../../LoaderDel";
import { Link } from "react-router-dom";
import { LoaderChico } from "../../LoaderChico";
import { BACKEND } from "../../backend";
import Table from 'react-bootstrap/Table';
import imageBanner from "../../images/imageBannerDesk.png";
import imageBannerMobile from "../../images/imageBannerMob.png";

registerLocale("es", es);
setDefaultLocale("es");
const AdminVacantes = () => {
  const [show, setShow] = useState(false);
  const [showDel, setShowDel] = useState(false);
  const [pre, setPre] = useState(false);
  const [load, setLoad] = useState(false);
  const [calendario, setCalendario] = useState(false);
  const [calendarioOpt, setCalendarioOpt] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showTitle, setShowTitle] = useState("");
  const [job, setJob] = useState("");
  const [store, setStore] = useState("");
  const [del, setDel] = useState();
  const [ opt, setOpt ] = useState([]);
  const [ dashboard, setDashboard ] = useState([]);
  const [ optBack, setOptBack ] = useState([]);
  const [ search, setSearch ] = useState(null);
  const [modalshow, setModalshow] = useState(null);
  const [oportunidades, setOportunidades] = useState({
    messages: `Hola,  Ante todo, gracias por tu interés en trabajar en Decathlon y por el tiempo que has dedicado a este proceso de selección. Este mail es para informarte de que tu perfil no se ajusta completamente a esta oferta pero… No te rindas! Hemos dejado tu candidatura en nuestra base de datos para que tengas nuevas oportunidades. Un saludo!`,
  });

  const [errors, setErrors] = useState({
    store_id: "",
    charge_id: "",
  });
  const [res, setRes] = useState(null);
  const [resDel, setResDel] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const baseUrl = BACKEND + "api/cms/jobs/update";
  const baseUrlDel = BACKEND + "api/cms/jobs/";


  const [sportName, setSportName] = useState(null);
  const [ storesDash, setStoresDash ] = useState([]);




    const setUserStorage = async () => {
        try {
            const response = await axios({
                url: BACKEND + "api/common/profile",
                method: "GET",
                headers: { Authorization: "Bearer " + token },
            });
            localStorage.setItem('userStorage', JSON.stringify(response.data));
        } catch (e) {
            console.log("error setUserStorage");
        }
    }

    useEffect(() => {
        let userStorage = localStorage.getItem('userStorage');
        if(!userStorage){
            setUserStorage();
        }
    }, []);

  useEffect(() => {
    // setShowTitle(job + store);
    setForm({ ...form, name: showTitle });

    if(pre?.charge_id === 1 && sportName){ // only in Lider deporte = 1
        let newTitle = `${pre.charge} ${sportName} en ${pre.store}`;
        setShowTitle(newTitle);
    }else{
        setShowTitle(job + store);
    }

  }, [job, store, showTitle, sportName]);
  const [form, setForm] = useState({
    id: "",
    name: "",
    description_project: "",
    description_responsibilities: "",
    description_who_you_are: "",
    store_id: "",
    charge_id: "",
    schedule_id: "",
    duration_period: "",
    sport_id: "",
    // image: "",
  });
  // Datos de los Jobs


    const fetchVacancies = async () => {
        setLoad(true);
        await axios({
            url: `${BACKEND}api/cms/jobs-applicants`,
            method: "GET",
            headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
            setOpt(response.data.data);
            setOptBack(response.data.data);
        })
        .catch((error) => {
            console.log(error);
        })
        .finally(() => {
            setLoad(false);
        })
    };

    useEffect(() => {
        fetchVacancies();
    }, []);

    const orderBy = key => {
      return [...opt].sort((a, b) => (a[key] > b[key] ? 1 : -1));
    };

    useEffect(() => {
      if (opt.length > 0) {
        const sortedData = orderBy('store_name');
        setOpt(sortedData);
      }
    }, [optBack]);

    const fetchDashboard = async () => {
      setLoad(true);
      await axios({
          url: `${BACKEND}api/cms/jobs-dashboard`,
          method: "GET",
          headers: { Authorization: "Bearer " + token },
      })
      .then((response) => {
          setDashboard(response.data.data);
      })
      .catch((error) => {
          console.log(error);
      })
      .finally(() => {
          setLoad(false);
      })
  };
  useEffect(() => {
    fetchDashboard();
  }, []);

  const handleSearch = value => {
      let copyOpt = [...optBack];
      if (value) {
        const lowercasedValue = value.toLowerCase().trim();
        const filteredData = copyOpt.filter(item => {
          return (
            item.job_name.toString().toLowerCase().includes(lowercasedValue) ||
            item.store_name.toString().toLowerCase().includes(lowercasedValue)
          );
        });
        setOpt(filteredData);
      } else {
        setOpt(optBack);
      }
    };
  // Modal

  const handleClose = () => {
    setShow(false);
    setShowDel(false);
    setSportName(null);
  };
  const handleShow = (vac) => {
    if (vac.job_duration === "Determinado") {
      setCalendarioOpt("Determinado");
      setCalendario(false);
      setForm({ ...form, duration_period: "Determinado" });
    }
    if (vac.job_duration !== "Determinado") {
      setCalendarioOpt("Indeterminado");
      setCalendario(true);
      let datos = vac.job_duration.split(",");
      setStartDate(moment(datos[0]).toDate());
      setEndDate(moment(datos[1]).toDate());
    }
    setShow(true);
    setJob(vac.job_name);
    setPre({
      id: vac.job_id,
      name: "",
      description_project: vac.job_description_project,
      description_responsibilities: vac.job_description_responsibilities,
      description_who_you_are: vac.job_description_who_you_are,
      store_id: vac.store_id,
      sport_description: vac.sport,
      charge_id: vac.charge_id,
      schedule_id: vac.schedule_id,
      duration_period: vac.job_duration,
      sport_id: vac.sport_id,
      charge: vac.charge,
      store: vac.store_name,
    });
    setForm({
      id: vac.job_id,
      name: job,
      description_project: vac.job_description_project,
      description_responsibilities: vac.job_description_responsibilities,
      description_who_you_are: vac.job_description_who_you_are,
      store_id: vac.store_id,
      // sport_description: vac.sport_description,
      charge_id: vac.charge_id,
      schedule_id: vac.schedule_id,
      duration_period: vac.job_duration,
      sport_id: vac.sport_id,
    });
  };
  useEffect(() => {
    if (pre.duration_period === "Determinado") {
      setCalendarioOpt("Determinado");
      setForm({ ...form, duration_period: "Determinado" });
    }
    if (pre.duration_period !== "Determinado") {
      setCalendarioOpt("Indeterminado");
      setCalendario(true);
    }
  }, []);
  const handleDelete = (vac) => {
    setShowDel(true);
    setDel(vac.job_id);
    console.log("Var: api/cms/jobs/" + vac.job_id );
  };

  // Modal body Form para actualizar

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setForm({ ...form, duration_period: start + ", " + end });
  };
  const onChangeStore = (value) => {
    setJob("");
    setForm({ ...form, store_id: value.value });
    setStore(value.label);
    setErrors({ ...errors, store_id: false });
  };
  const onChangeJob = (value) => {
    setForm({ ...form, charge_id: value.value });
    setJob(value.label + " en ");
    setErrors({ ...errors, charge_id: false });
  };
  const onChangeSchedule = (value) => {
    setForm({ ...form, schedule_id: value.value });
  };
  const onChangeSport = (id) => {
    setForm({ ...form, sport_id: id });
  };
  const onChangeProj = (e) => {
    setForm({ ...form, description_project: e.target.value });
  };
  const onChangeRespo = (e) => {
    setForm({ ...form, description_responsibilities: e.target.value });
  };
  const onChangeWho = (e) => {
    setForm({ ...form, description_who_you_are: e.target.value });
  };
  const onChangeCalendario = (value) => {
    if (value.value === true) {
      setCalendario(value.value);
    } else if (value.value === false) {
      setForm({ ...form, duration_period: "Determinado" });
      setCalendario(value.value);
    }
  };
  const Validacion = () => {
      setLoading(true);
      fetchData();
      setLoading(false);
  };

  const fetchData = async () => {
    try {
        setLoad(true);
      const resp = await axios({
        url: baseUrl,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: form,
      });
      Load(resp.data);
      setRes(resp);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShow(false);
      }, 2500);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setTimeout(() => {
        setLoad(true);
        window.location.reload();
      }, 2500);
    }
  };

  const Load = (resDel) => {
    return <LoaderDel resultado={resDel} />;
  };

  const fetchArchive = async () => {
    try {
      const response = await axios({
        url: baseUrlDel + del,
        method: "DELETE",
        headers: { Authorization: "Bearer " + token },
      });
      Load(response.data);
      setResDel(response);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
      setError(null);
    } catch (error) {
      Load(error.data);
      setResDel(error);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
    } finally {
      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 2500);
    }
  };

  const fetchDelete = async () => {

    try {
      const response = await axios({
        url: BACKEND + "api/cms/jobs/delete/" + del,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });

      console.log("DELETE:" + BACKEND + "api/cms/jobs/delete/" + del);
      Load(response.data);
      setResDel(response);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
      setError(null);
    } catch (error) {
      Load(error.data);
      setResDel(error);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
    } finally {
      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 2500);
    }
  };


  const fetchReopen = async (job) => {
    toast.info('Espere, por favor', { position: "top-center" });
    try {
      const response = await axios({
        url: BACKEND + "api/cms/jobs/reopen/" + job.job_id,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      Load(response.data);
      setResDel(response);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
      setError(null);
    } catch (error) {
      Load(error.data);
      setResDel(error);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setShowDel(false);
      }, 2500);
    } finally {
      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 2500);
    }
  };


  dashboard.forEach(element => {
    if(element.store == "País"){
      storesDash[0] = { vacantes: element.vacantes, offers: element.offers, dataB: element.dataB, hired: element.hired };
    }
  });


  return (
    <>
      <SubNavBack />
      <ToastContainer />
      <div className="container-fluid  bg-back">
        <div className="row justify-content-center">
          <div className="col-10  bg-white bgShadow mb-3 animate__animated animate__fadeIn">
            <div className="row  justify-content-center">

              <a href="https://drive.google.com/file/d/1yfMhyYAIcRJHnCNN556SBc83r3kS8eDm/view">
                <Image src={ isMobile ? imageBannerMobile : imageBanner } style={{ width: "100%" }} />
              </a>
              <div className="titBack text-left col-6 my-md-5 pt-md-5">
                ADMINISTRAR MIS VACANTES
              </div>
            {/* </div> */}
              {/* <Row>
                <Col style={{ padding: "0" }}>
                  <a href="https://drive.google.com/file/d/1yfMhyYAIcRJHnCNN556SBc83r3kS8eDm/view">
                    <Image src={ isMobile ? imageBannerMobile : imageBanner } style={{ width: "100%" }} />
                  </a>
                </Col>
              </Row> */}
              <div className="row  mb-3 col-10 ">
                <Table responsive striped bordered hover className="center">
                  <thead>
                    <tr>
                      <th>Ofertas activas</th>
                      <th>Total de postulantes</th>
                      <th>Perfiles en Base de Datos</th>
                      <th>Contratados</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                    <td>                
                        {storesDash.map((dash, i) => (
                            <React.Fragment key={i}>
                                {dash.vacantes}
                            </React.Fragment>
                          ))
                        }
                      </td>
                      <td>                
                        {storesDash.map((dash, i) => (
                            <React.Fragment key={i}>
                                {dash.offers}
                            </React.Fragment>
                          ))
                        }
                      </td>
                      <td>                
                        {storesDash.map((dash, i) => (
                            <React.Fragment key={i}>
                                {dash.dataB}
                            </React.Fragment>
                          ))
                        }
                      </td>
                      <td>                
                        {storesDash.map((dash, i) => (
                            <React.Fragment key={i}>
                                {dash.hired}
                            </React.Fragment>
                          ))
                        }
                      </td>
                    </tr>

                  </tbody>
                </Table>
              </div>

            </div>
            <div className="row mb-3">
                <div className="col-10 offset-md-1 p-2"  style={{ backgroundColor: "#98C8ED" }}>
                    <div className="row">
                        <div className="col-12">
                            <b className="title-jobs mb-1" style={{ color: "#fff" }}>Buscar:</b>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Escribe un oficio, una ubicación o el nombre del candidato"
                                onChange={(e) => handleSearch(e.target.value) }
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="row justify-content-center">
              <div
                style={{
                  height: opt !== null && opt.length > 10 && "560px",
                }}
                className={
                  opt && opt !== null && opt.length > 10
                    ? "col-10 mb-5 pb-5 overflow-auto"
                    : "col-10 mb-5 pb-5"
                }
              >
                <table className="table ">
                  <thead>
                    <tr className="table-active bg-tableTH">
                      <th scope="col">ESTATUS</th>
                      <th scope="col ">
                        <span className="link-order">
                          OFICIO 
                        </span>
                      </th>
                      <th scope="col ">
                        <span className="link-order">
                          UBICACIÓN
                        </span>
                      </th>
                      <th scope="col">POSTULANTES</th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="table-secondary">

                    { opt.length === 0 &&
                        <tr>
                            <td
                                className="text-center align-middle font-weight-bold h4 mb-5"
                                style={{ height: "100px", color: "#8e8e8e" }}
                                colSpan="100%"
                            >
                                { !load && <>No hay datos</> }
                                { load && <LoaderChico /> }
                              <br />
                            </td>
                        </tr>
                    }
                    { opt.length !== 0 && opt.map((vac, i) => (
                        <React.Fragment key={i}>
                          <tr
                            key={vac.job_id}
                            data-toggle="collapse"
                            data-target={`${"#t" + i}`}
                            className="accordion-toggle bg-back animate__animated animate__fadeInDown cursor-pointer"
                          >
                            <td>
                                {(() => {
                                    switch (vac.job_active) {
                                      case 0:
                                        return "Archivado"
                                      case 1:
                                        return "Activo"
                                      case 2:
                                        return "Cerrado"
                                      default:
                                        return null
                                    }
                                })()}
                            </td>
                            <td>{vac.job_name}</td>
                            <td>{vac.store_name}</td>
                            <td>
                              {Object.entries(vac.applicants[0].applicant_data_pending).length + Object.entries(vac.applicants[0].applicant_data_inprocess).length}
                            </td>
                            <td></td>
                            <td>
                              {vac.job_active === 1 && (
                                <div
                                  className="btnEliminar"
                                  onClick={() => {
                                    handleDelete(vac);
                                  }}
                                >
                                  <AiOutlineMore />
                                </div>
                              )}
                              {vac.job_active === 0 && (
                                <div
                                  className="btnOpen"
                                  onClick={() => {
                                    fetchReopen(vac);
                                  }}
                                >
                                  <AiFillPlayCircle />
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr key={i}>
                            <td colSpan="12" className="hiddenRow">
                              <div
                                id={"t" + i}
                                className="accordian-body collapse my-1 ml-4"
                              >
                                    <React.Fragment key={i + 1}>
                                      <tr>
                                        <td  className=" col-3 text-center ">
                                            <tr> 
                                                <td
                                                    key={vac.job_id + 2 }
                                                    data-toggle="collapse"
                                                    data-target={`${"#t" + i + 2 }`}
                                                    className="accordion-toggle animate__animated animate__fadeInDown cursor-pointer btn-seg"
                                                >
                                                <AiOutlineUnorderedList />
                                                Total: {vac.applicants[0].applicant_data_pending.length + vac.applicants[0].applicant_data_inprocess.length}
                                                </td>
                                              </tr>
                                        </td>
                                        <td className=" col-3 text-center " >
                                            <tr> 
                                              <td
                                                key={vac.job_id + 1}
                                                data-toggle="collapse"
                                                data-target={`${"#t" + i + 1 }`}
                                                className="accordion-toggle animate__animated animate__fadeInDown cursor-pointer btn-seg"
                                              >
                                                <AiOutlineEyeInvisible />
                                              No tratados: {vac.applicants[0].applicant_data_pending.length}
                                              </td>
                                            </tr>
                                        </td>
                                        <td className=" col-3 text-center ">
                                          <tr> 
                                            <td              
                                              key={vac.job_id + 3 }
                                              data-toggle="collapse"
                                              data-target={`${"#t" + i + 3 }`}
                                              className="accordion-toggle animate__animated animate__fadeInDown cursor-pointer btn-seg"
                                            >
                                              <AiFillMail/>
                                              En proceso: {vac.applicants[0].applicant_data_inprocess.length}
                                            </td>
                                          </tr>
                                        </td>
                                        <td  className=" col-3 text-center ">
                                            <tr className="btn-seg-gray animate__animated animate__fadeInDown" >
                                              <td >
                                              <AiTwotoneCarryOut/>
                                                Procesos Finalizados: {vac.job_finished}
                                              </td>
                                            </tr>
                                            <tr className="btn-seg-green animate__animated animate__fadeInDown" >
                                              <td >
                                                <AiTwotoneCarryOut/>
                                                Contratados: {vac.job_hired}
                                              </td>
                                            </tr>
                                        </td>
                                      </tr>
                                      <tr key={i + 2}>
                                        <td colSpan="10" className="hiddenRow">
                                          <div
                                            id={"t" + i + 2}
                                            className="accordian-body collapse my-1 ml-4"
                                          >
                                            No tratados:
                                            { vac.applicants[0].applicant_data_pending.length !== 0 && vac.applicants[0].applicant_data_pending.map((app, i) => (
                                                  <div className="row my-3" key={i}>
                                                    <div className="col-9 ">
                                                      {app.applicant_data_pending.name +
                                                        " " +
                                                        app.applicant_data_pending.last_name +
                                                        "  / Desde: " + 
                                                        app.applicant_data_pending.created_at + " "
                                                              }
                                                    </div>
                                                    <div className="col-3 align-middle">
                                                      <Link
                                                        className="btn-ver"
                                                        target="_blank"
                                                        to={
                                                          `./admin-status/` +
                                                          app.applicant_data_pending.id
                                                        }
                                                      >
                                                        Ver perfil <FormNext color="white" />
                                                      </Link>
                                                    </div>
                                                  </div>
                                                
                                            ))} 
                                            En Proceso:
                                            { vac.applicants[0].applicant_data_inprocess.length !== 0 && vac.applicants[0].applicant_data_inprocess.map((app, i) => (
                                                  <div className="row my-3" key={i}>
                                                    <div className="col-9 ">
                                                      {app.applicant_data_inprocess.name +
                                                        " " +
                                                        app.applicant_data_inprocess.last_name +
                                                        "  / Desde: " + 
                                                        app.applicant_data_inprocess.created_at + " "
                                                              }
                                                    </div>
                                                    <div className="col-3 align-middle">
                                                      <Link
                                                        className="btn-ver"
                                                        target="_blank"
                                                        to={
                                                          `./admin-status/` +
                                                          app.applicant_data_inprocess.id
                                                        }
                                                      >
                                                        Ver perfil <FormNext color="white" />
                                                      </Link>
                                                    </div>
                                                  </div>
                                                
                                            ))} 
                                          </div>
                                        </td>
                                      </tr>
                                      <tr key={i + 1}>
                                        <td colSpan="10" className="hiddenRow">
                                          <div
                                            id={"t" + i + 1}
                                            className="accordian-body collapse my-1 ml-4"
                                          >
                                            No tratados:
                                            { vac.applicants[0].applicant_data_pending.length !== 0 && vac.applicants[0].applicant_data_pending.map((app, i) => (
                                                  <div className="row my-3" key={i}>
                                                    <div className="col-9 ">
                                                      {app.applicant_data_pending.name +
                                                        " " +
                                                        app.applicant_data_pending.last_name +
                                                        "  / Desde: " + 
                                                        app.applicant_data_pending.created_at + " "
                                                              }
                                                    </div>
                                                    <div className="col-3 align-middle">
                                                      <Link
                                                        className="btn-ver"
                                                        target="_blank"
                                                        to={
                                                          `./admin-status/` +
                                                          app.applicant_data_pending.id
                                                        }
                                                      >
                                                        Ver perfil <FormNext color="white" />
                                                      </Link>
                                                    </div>
                                                  </div>
                                                
                                            ))} 
                                          </div>
                                        </td>
                                      </tr>
                                     
                                      
                                      <tr key={i + 3}>
                                        <td colSpan="10" className="hiddenRow">
                                          <div
                                            id={"t" + i + 3}
                                            className="accordian-body collapse my-1 ml-4"
                                          >
                                            En proceso:
                                            { vac.applicants[0].applicant_data_inprocess.length !== 0 && vac.applicants[0].applicant_data_inprocess.map((app, i) => (
                                                  <div className="row my-3" key={i}>
                                                    <div className="col-9 ">
                                                      {app.applicant_data_inprocess.name +
                                                        " " +
                                                        app.applicant_data_inprocess.last_name +
                                                        "  / Desde: " + 
                                                        app.applicant_data_inprocess.created_at + " "
                                                              }
                                                    </div>
                                                    <div className="col-3 align-middle">
                                                      <Link
                                                        className="btn-ver"
                                                        target="_blank"
                                                        to={
                                                          `./admin-status/` +
                                                          app.applicant_data_inprocess.id
                                                        }
                                                      >
                                                        Ver perfil <FormNext color="white" />
                                                      </Link>
                                                    </div>
                                                  </div>
                                                
                                            ))} 
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modalW"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="bgBlue">
          <div className=" container">
            <div className="titBack text-center col-12 my-1 text-white ">
              EDITAR UNA VACANTE
            </div>
          </div>
        </Modal.Header>
        {!loading ? (
          <>
            <Modal.Body>
              <div className="row justify-content-center">
                <div className="col-10">
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4" className="titInputBack">
                          TÍTULO
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="inputEmail4"
                          value={
                            pre.job_name
                              ? pre.job_name
                              : showTitle.length === 0
                              ? " "
                              : showTitle
                          }
                          disabled
                        />
                      </div>
                    </div>

                    <div className="form-row pt-md-3">
                      <div className="form-group col-md-4">
                        <label className="titInputBack" htmlFor="inputState">
                          TIENDA/CORPORATIVO
                        </label>
                        <div
                          style={{
                            border:
                              errors.store_id == true && "1.1px solid #ff0000",
                            borderRadius: errors.store_id == true && "5px",
                          }}
                        >
                          <SelectListStore
                           dis={true}
                            title="store"
                            List="store-list"
                            deft={pre.store_id}
                            onChange={onChangeStore}

                          />
                        </div>
                        {errors.store_id == true && (
                          <p className="text-danger text-errors">
                            {" "}
                            El campo Tienda es requerido, seleccione una opción.
                          </p>
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <label className="titInputBack" htmlFor="inputState">
                          OFICIO
                        </label>
                        <div
                          style={{
                            border:
                              errors.charge_id == true && "1.1px solid #ff0000",
                            borderRadius: errors.charge_id == true && "5px",
                          }}
                        >
                          <SelectListJobs
                            title="jobs"
                            List="jobs-list"
                            deft={pre.charge_id}
                            onChange={onChangeJob}
                            dis={true}
                          />
                        </div>
                        {errors.charge_id == true && (
                          <p className="text-danger text-errors">
                            {" "}
                            El campo Oficio es requerido, seleccione una opción.
                          </p>
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <label className="titInputBack" htmlFor="inputState">
                          DISPONIBILIDAD
                        </label>
                        <SelectListDispo
                          title="Schedule"
                          List="schedule-list"
                          deft={pre.schedule_id}
                          onChange={onChangeSchedule}
                        />
                      </div>
                    </div>
                    <div className="form-row pt-md-3 justify-content-between">
                      <div className="form-group col-md-6 pr-4">
                        <label className="titInputBack" htmlFor="inputState">
                          DEPORTE PRACTICADO
                        </label>
                        <SelectListSports
                          url={BACKEND+"api/data-register"}
                          cat="cat_sports"
                          deft={pre.sport_id}
                          dtxt={pre.sport_description}
                          onChange={onChangeSport}
                          setSportName={setSportName}
                        />
                      </div>
                      <div className="form-group  col-md-6 pl-4">
                        <label className="titInputBack">
                          DURACIÓN DE LA OFERTA
                        </label>
                        <SelectListCalendar
                          deft={calendarioOpt}
                          onChange={onChangeCalendario}
                        />
                        {calendario && (
                          <>
                            <br />
                            <DatePicker
                              className="p-2 inputCalendar pt-2"
                              selected={startDate}
                              onChange={onChange}
                              startDate={startDate}
                              endDate={endDate}
                              selectsRange
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              locale="es"
                              dateFormat="dd/MM/yyyy"
                              minDate={new Date()}
                              showWeekNumbers
                            />{" "}
                            {startDate && endDate && (
                              <span className="pl-4">
                                <br />
                                Desde:{" "}
                                <span className="  text-primary ">
                                  {" "}
                                  {moment(startDate).format("LL")}
                                </span>{" "}
                                hasta:
                                <span className="text-primary">
                                  {" "}
                                  {moment(endDate).format("LL")}{" "}
                                </span>{" "}
                                .
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <label className="titInputBack pt-md-3">
                      DESCRIPCIÓN DE LA VACANTE
                    </label>
                    <div className="form-row">
                      <div className="form-group col-12 pt-md-1">
                        <label className="titAreaBack">EL PROYECTO</label>
                        <textarea
                          className="form-control"
                          rows="4"
                          onChange={onChangeProj}
                          defaultValue={pre.description_project}
                          maxLength="4000"

                        ></textarea>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-12">
                        <label className="titAreaBack">
                          TUS RESPONSABILIDADES
                        </label>
                        <textarea
                          className="form-control"
                          rows="4"
                          onChange={onChangeRespo}
                          defaultValue={pre.description_responsibilities}
                          maxLength="4000"

                        ></textarea>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-12">
                        <label className="titAreaBack">QUIÉN ERES</label>
                        <textarea
                          className="form-control"
                          rows="4"
                          onChange={onChangeWho}
                          defaultValue={pre.description_who_you_are}
                          maxLength="4000"

                        ></textarea>
                      </div>
                    </div>
                    {/* <div className=" col-12   align-items-center text-center my-5">
                  <span className="btn-verGris " to={`/admin-vacantes`}>
                    VISTA PREELIMINAR <FormNext color="white" />
                  </span>
                </div>
                <div className=" col-12   align-items-center text-center my-5">
                  <span className="btn-ver">
                    VER MÁS <FormNext color="white" />
                  </span>
                </div> */}
                  </form>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  Validacion();
                }}
              >
                Actualizar
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <Loader s="Vacante actualizada correctamente" />
        )}
      </Modal>

      <Modal
        show={showDel}
        onHide={handleClose}
        dialogClassName=""
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="bgBlue">
          <div className=" container">
            <div className="titBack text-center col-12 my-1 text-white ">
              GESTIONAR VACANTE
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            Load(resDel)
          ) : (

            <>
              <div className="row justify-content-center mt-3">
                <div className="col-10">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4" className="titInputBack">
                        Desea <b> eliminar ó pausar</b> esta vacante?
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row my-3 justify-content-center text-center">
                <div className="col-4">
                  <Button variant="primary" onClick={handleClose}>
                    Cancelar
                  </Button>
                </div>
                <div className="col-4">
                  <Button
                    variant="danger"
                    onClick={() => {
                      //setModalshow(1);
                      fetchDelete();
                    }}
                  >
                    Eliminar
                  </Button>
                </div>
                <div className="col-4">
                  <Button
                    variant="danger"
                    onClick={() => {
                      //setModalshow(1);
                      fetchArchive();
                    }}
                  >
                    Pausar
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdminVacantes;