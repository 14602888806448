import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button, Form, Alert } from 'react-bootstrap';
import axios from "axios";
import { BACKEND } from "../../backend";
import Loader from '../baseDatosCv/Loader';

const Comments = ({ show, setShow, userId, toast }) => {

    const [ comments, setComments ] = useState([]);
    const [ comment, setComment ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ isSubmit, setIsSubmit ] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const token = localStorage.getItem("token");

    const getComments = async () => {
        setLoading(true);
        await axios({
            url: BACKEND + "api/cms/user-comments/" + userId,
            method: "GET",
            headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
            setComments(response.data.data);
        })
        .catch((error) => {
            console.log(error);
            toast.error(error);
        })
        .finally((e) => {
            setLoading(false);
        });
    }

    useEffect(() => {
        if(show) getComments();
    }, [show]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let userStorage = JSON.parse(localStorage.getItem('userStorage'));
        setIsSubmit(true);
        await axios({
            url: BACKEND + "api/cms/user-comment",
            method: "POST",
            headers: { Authorization: "Bearer " + token },
            data: { user_id: userId, comment: comment, created_by: userStorage.name },
        })
        .then((response) => {
            let message = response.data.message;
            response.data.success === 1 ? toast.success(message) : toast.warning(message);
        })
        .catch((error) => {
            console.log(error);
            toast.error(error);
        })
        .finally((e) => {
            handleClose();
            setComment('');
            setIsSubmit(false);
        });
    }

    return (
        <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Comentarios</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ?
                    <Loader />
                :
                    <div className="overflow-auto" style={{ maxHeight: '400px' }}>
                        { comments ?
                            <ul className="list-group list-group-flush">
                                { comments.map( comment => (
                                    <li className="list-group-item" key={comment.id}>
                                        <p className="mb-2">
                                            <b>{comment.created_by}</b>
                                            <label className="float-right text-muted font-italic" style={{fontSize: '14px'}}>{comment.created_at}</label>
                                        </p>
                                        <p className="mb-0 text-muted">{comment.comment}</p>
                                    </li>
                                ))}
                            </ul>
                        :
                            <Alert variant='warning' className="text-center mb-0"><b>No hay comentarios registrados.</b></Alert>
                        }
                    </div>
                }

                <Form onSubmit={handleSubmit} autoComplete="off">
                    <hr />
                    <Row>
                        <Col xs={12}>
                            <Form.Control
                                size="sm"
                                name="comment"
                                placeholder="Escribe tu comentario"
                                required
                                value={comment}
                                onChange={e => setComment(e.target.value)}
                                maxLength="650"
                            />
                        </Col>
                        <Col xs={12}>
                            <Button variant="secondary" className="mt-2 float-right" onClick={handleClose}>
                                Cerrar
                            </Button>
                            <Button
                                type="submit"
                                className="mt-2 float-right mr-1"
                                disabled={isSubmit}
                            >
                                Enviar comentario
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default Comments;
