import React, { useEffect, useState } from 'react';
import axios from "axios";
import { BACKEND } from "../../backend";
import {
    Container,
    Col,
    Row,
    Accordion,
    Card,
    Button,
    Image,
    Toast,
    Pagination,
    Alert
} from "react-bootstrap";
import SubNavBack from "../auth/subNavBack";
import CustomSelect from './CustomSelect';
import ApplicantsList from './ApplicantsList';
import Loader from './Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Comments from '../auth/Comments';

const Index = (props) => {

    const token = localStorage.getItem("token");

    // Plural
    const [ stores, setStores ] = useState([]);
    const [ locations, setLocations ] = useState([]);
    const [ jobs, setJobs ] = useState([]);
    const [ jobsApply, setJobsApply ] = useState([]);
    const [ applicants, setApplicants ] = useState([]);
    const [ filterApplicants, setFilterApplicants ] = useState([]);
    const [ schedules, setSchedules ] = useState([]);
    const [ sports, setSports ] = useState([]);
    const [ levels, setLevels ] = useState([
        { value: "1", label: "Básico" },
        { value: "2", label: "Intermedio " },
        { value: "3", label: "Avanzado" },
    ]);

    const [show, setShow] = useState(false);

    // comments
    const [ showComments, setShowComments ] = useState(false);
    const [ userId, setUserId ] = useState(null);

    // Singular
    const [ loadingApplicants, setLoadingApplicants ] = useState(false);
    const [ store, setStore ] = useState(null);
    const [ location, setLocation ] = useState(null);
    const [ job, setJob ] = useState(null);
    const [ sport, setSport ] = useState(null);
    const [ level, setLevel ] = useState(null);
    const [ schedule, setSchedule ] = useState(null);


    const clearFilter = () => {
        setFilterApplicants(applicants);
        setStore(null);
        setJob(null);
        setLocation(null);
        setSport(null);
        setLevel(null);
        setSchedule(null);
    }

    const formatForOptions = (data, keyValue, keyLabel) => {
        return data.map(entry => ({label: entry[keyLabel], value: entry[keyValue]}));
    }

    const formatForOptionsSports = (data, keyValue, keyLabel) => {
        return data.map(entry => ({label: entry[keyLabel], value: entry[keyValue].map(x => x.description).join(', ') }));
    }

    useEffect(() => {
        clearFilter();
        fetchStores();
        fetchJobs();
        fetchJobsApply();
        fetchApplicants();
        fetchSports();
        fetchSchedules();
    }, []);

    const multiFilter = (arr, filters) => {

		const filterKeys = Object.keys(filters);
		return arr.filter(eachObj => {
			return filterKeys.every(eachKey => {
				if (!filters[eachKey].length) {
            		return true; // passing an empty filter means that filter is ignored.
        		}
                console.log("Log: " + eachObj[eachKey] + "--" + filters[eachKey] + "--" + eachKey);
                if(eachKey == "sport_1_desc"){
                    console.log("Entra en sport filter ");
                    if(filters[eachKey].includes(eachObj["sport_1_desc"]) || filters[eachKey].includes(eachObj["sport_2_desc"]) || filters[eachKey].includes(eachObj["sport_3_desc"])){
                        console.log("Entra en filtros OR ||");
                        return true;
                    }
                }
                // else if(filters[eachKey] == '1' || filters[eachKey] == '2'){
                //     if(eachObj[eachKey] == '1' || eachObj[eachKey] == '2'){
                //         return true;
                //     }
                // }
                
                else{
                        return filters[eachKey].includes(eachObj[eachKey]);
                }
                
    		});
		});
	};

    useEffect(() => {
        let filters = {
            store: '',
            job: '',
            city: '',
            sport_1_desc: '',
            sport_1_level: '',
            schedule_id: '',
        };

        if(store) filters.store = `${store.label}`;
        if(job) filters.job = `${job.label}`;
        if(location) filters.city = `${location.label}`;
        if(sport) filters.sport_1_desc = `${sport.value}`;
        if(level) filters.sport_1_level = `${level.value}`;
        if(schedule) filters.schedule_id = `${schedule.value}`;
        // console.log(multiFilter(applicants, filters), filters);
        let filterData = multiFilter(applicants, filters);
        setFilterApplicants(filterData);
    }, [store, location, job, sport, level, schedule]);

    const fetchStores = async () => {
        await axios.get(`${BACKEND}api/store-list`)
            .then((response) => {
                let formatingStores = formatForOptions(response.data.data, 'id', 'store_description');
                setStores(formatingStores);
                let locationsArr = [];
                response.data.data.reduce((res, value)  => {
                    if (!res[value.store_location]) {
                        res[value.store_location] = { value: value.store_location, label: value.store_location };
                        locationsArr.push(res[value.store_location])
                    }
                    return res;
                }, {});
                setLocations(locationsArr);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchJobs = async () => {
        await axios.get(`${BACKEND}api/jobs-offering`)
            .then((response) => {
                let formatingResponse = formatForOptions(response.data.data, 'job_name_id', 'description');
                setJobs(formatingResponse);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchJobsApply = async () => {
        await axios.get(`${BACKEND}api/jobs-list/all`)
            .then((response) => {
                let formatingResponse = formatForOptions(response.data.data, 'job_id', 'job_name');
                setJobsApply(formatingResponse);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchSchedules = async () => {
        await axios.get(`${BACKEND}api/cms/schedule-list-db`, {
            headers: {
                Authorization: "Bearer " + token
            }
        })
            .then((response) => {
                let formatingResponse = formatForOptions(response.data.data, 'schedule_id', 'schedule_name');
                setSchedules(formatingResponse);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const fetchApplicants = async () => {
        setLoadingApplicants(true);
        await axios.get(`${BACKEND}api/cms/jobs-applicants-v2`, {
            headers: {
                Authorization: "Bearer " + token
            }
        })
            .then((response) => {
                setApplicants(response.data.data);
                setFilterApplicants(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally((e) => {
                setLoadingApplicants(false);
            });
    };

    const fetchSports = async () => {
        await axios.get(`${BACKEND}api/cms/sport-list`, {
            headers: {
                Authorization: "Bearer " + token
            }
        })
            .then((response) => {

                let formatingResponse = formatForOptionsSports(response.data.data, 'sub_sports', 'sport_name');
                setSports(formatingResponse);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const applyJob = async (data) => {

        setLoadingApplicants(true);

        await axios({
            url: BACKEND + "api/applicant/apply",
            method: "POST",
            headers: { Authorization: "Bearer " + token },
            data: data
        })
            .then((response) => {
                let message = response.data.message;
                response.data.success === 1 ? toast.success(message) : toast.warning(message);
                fetchApplicants();
            })
            .catch((error) => {
                console.log(error);
                toast.error(error);
            })
            .finally((e) => {
                setLoadingApplicants(false);
            });
    };

    const handleShowComments = id => {
        setUserId(id);
        setShowComments(true);
    }

    return (
        <div>
            <SubNavBack />
            <Comments
                show={showComments}
                setShow={setShowComments}
                userId={userId}
                toast={toast}
            />
            <div className="container-fluid  bg-back">
                <div className="row justify-content-center">
                    <div className="col-10 bg-white bgShadow mb-3 animate__animated animate__fadeIn">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="row justify-content-center">
                                    <Col sm={3} xs={12} className="text-center m-0 p-0" />
                                    <div className="titBack text-center col-6 my-md-5 pt-md-5 my-3 pt-3">
                                        BASE DE DATOS CV’S
                                    </div>
                                    <Col sm={3} xs={12} className="text-center my-md-3 pt-md-3  my-2 pt-2">
                                        <span
                                            className="btn-limpiar px-3 cursor-pointer  "
                                            onClick={() => clearFilter()}
                                            >
                                            Limpiar Filtros
                                        </span>
                                    </Col>
                                </div>
                                <ToastContainer />
                                <Row
                                    style={{
                                        backgroundColor: "#98C8ED",
                                        paddingBottom: "1em",
                                        display: "flex",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <Col xs={12}  xl={4}>
                                        <CustomSelect
                                            title="TIENDA"
                                            titleColor="#fff"
                                            options={stores}
                                            value={store}
                                            setValue={setStore}
                                        />
                                    </Col>
                                    <Col xs={12}  xl={4}>
                                        <CustomSelect
                                            title="OFICIO"
                                            titleColor="#fff"
                                            options={jobs}
                                            value={job}
                                            setValue={setJob}
                                        />
                                    </Col>
                                    {/* <Col xs={12}  xl={4}>
                                        <CustomSelect
                                            title="UBICACIÓN"
                                            titleColor="#fff"
                                            options={locations}
                                            value={location}
                                            setValue={setLocation}
                                        />
                                    </Col> */}
                                </Row>
                                <Row
                                    style={{
                                        paddingTop: "1em",
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                    }}
                                >
                                    <Col xs={12}  xl={4}>
                                        <CustomSelect
                                            title="DEPORTE"
                                            titleColor="#000"
                                            options={sports}
                                            value={sport}
                                            setValue={setSport}
                                        />
                                    </Col>
                                    {/* <Col xs={12}  xl={4}>
                                        <CustomSelect
                                            title="GRADO DE PRÁCTICA"
                                            titleColor="#000"
                                            options={levels}
                                            value={level}
                                            setValue={setLevel}
                                        />
                                    </Col> */}
                                    <Col xs={12}  xl={4}>
                                        <CustomSelect
                                            title="DISPONIBILIDAD"
                                            titleColor="#000"
                                            options={schedules}
                                            value={schedule}
                                            setValue={setSchedule}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-5 mb-5">
                                    { show &&
                                        <Col className="p-0 text-center">
                                            <Alert variant="success" onClose={() => setShow(false)} dismissible>
                                                <Alert.Heading className="pt-1">Has aplicado correctamente a la vacante</Alert.Heading>
                                            </Alert>
                                        </Col>
                                    }
                                    <Col xs={12}  xl={12} className="p-0 overflow-auto border" style={{height: "400px"}}>
                                        <Accordion
                                            style={{ width: "100%", backgroundColor: "#E5E5E5" }}
                                        >
                                            <b
                                                style={{
                                                    position: "relative",
                                                    padding: "13px",
                                                    display: "block",
                                                }}
                                            >
                                                POSTULANTES
                                            </b>
                                        </Accordion>
                                        { loadingApplicants ?
                                            <Loader />
                                        :
                                            <ApplicantsList
                                                applicants={filterApplicants}
                                                clearFilter={clearFilter}
                                                applyJob={applyJob}
                                                jobsApply={jobsApply}
                                                stores={stores}
                                                jobs={jobs}
                                                handleShowComments={handleShowComments}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Index
