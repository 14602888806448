import React from "react";
import Select, { components } from "react-select";

const SelectListCalendar = ({  deft,onChange }) => {
  const { Option } = components;

  const CustomSelectOption = (props) => (
    <Option {...props}>{props.data.label}</Option>
  );

  const CustomSelectValue = (props) => <div>{props.data.label}</div>;

  let list = [];
    
  list = [{
    value: false,
    label: "Indeterminado",

  },
  {
    value: true,
    label:"Determinado",
  }];

  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 15,
      fontSize: 18,
      fontWeight: 600,
    }),
    control: (base, state) => ({
      ...base,
      padding: 5,
      color: "#000000",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#C4C4C4",
    }),
  };
  return (
    <>
      <Select
        
        styles={customStyles}
        className=" "
        options={list}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
        }}
        onChange={onChange}
        placeholder={deft ? deft : "Seleccione una opción" }
      />
    </>
  );
};

export default SelectListCalendar;
