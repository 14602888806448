import React from "react";
import Select, { components } from "react-select";
import { useShowList } from "../services/getDispoStatus";
// import { useShowList } from "../services/getShowStoreListB";

const SelectListDispoStatus = ({ List, onChange, deft }) => {
  const { Option } = components;

  const CustomSelectOption = (props) => (
    <Option {...props}>{props.data.label}</Option>
  );

  const CustomSelectValue = (props) => <div>{props.data.label}</div>;

  const { data } = useShowList(List);
  if (!data) return null;
  let options = [];
  let list = [];
  options = data;
  // list = options.filter((opt) => opt !== "cat_sports");
  list = options.cat_schedule.map((category) => ({
    value: category.id,
    label: category.description,
  }));
  const customStyles = {
    option: (provided) => ({
      ...provided,
      paddingLeft: 15,
      paddingTop: 3,
      paddingBottom: 3,
      fontSize: 15,
    }),
    control: (base, state) => ({
      ...base,
      padding: 0,
      color: "#000000",
      fontSize: 15
    }),
    input: (base, state) => ({
      ...base,
      height:28
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#C4C4C4",
    }),
  };
  let place
  if (deft) {
    place = list.filter(option => option.value === deft)
   }
  return (
    <>
      <Select
      // value={deft && list.filter(option => option.value === deft)}

        styles={customStyles}
        className="select-status "
        options={list}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
        }}
        defaultValue="a"
        onChange={onChange}
        placeholder={deft ?  place[0].label : "Seleccione una opción..." }
      />
    </>
  );
};

export default SelectListDispoStatus;
