import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Chat from "../components/chat";
import defaul from "../components/images/default.png";
import {
  FormCheckmark,
  FormClose,
  FormNext,
  FormNextLink,
  FormUp,
} from "grommet-icons";
import SelectListDispoStatus from "../components/selectListDispoStatus";
import SelectListGenStatus from "../components/selectListGenStatus";
import SelectListStoreStatus from "../components/selectListStoreStatus";
import SelectListStudiesStatus from "../components/selectListStudiesStatus";
import SelectListContactStatus from "../components/selectListContactStatus";
import axios from "axios";
import buso from "../components/images/selectedBuso.png";
import lvl1 from "../components/images/nivel1.png";
import lvl2 from "../components/images/nivel2.png";
import lvl3 from "../components/images/nivel3.png";

import acuaticos from "../components/images/sports/acuaticos.png";
import carrera from "../components/images/sports/carrera.png";
import caza from "../components/images/sports/caza.png";
import ciclismo from "../components/images/sports/ciclismo.png";
import colectivos from "../components/images/sports/colectivos.png";
import combate from "../components/images/sports/combate.png";
import gimnasia from "../components/images/sports/gimnasia.png";
import montaña from "../components/images/sports/montaña.png";
import olas from "../components/images/sports/olas.png";
import presicion from "../components/images/sports/presicion.png";
import raqueta from "../components/images/sports/raqueta.png";
import urbanos from "../components/images/sports/urbanos.png";
import { BACKEND } from "../components/backend";

const Status = () => {
  const token = localStorage.getItem("tokenUser");
  const baseUrl = BACKEND + "api/common/profile/update";
  const baseUrlStatus = BACKEND + "api/applicant/show-process/all";
  const baseUrlProfile = BACKEND + "api/common/profile";
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState(null);
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState(null);
  const [vacante, setVacante] = useState(0);
  const [user_id, setUser_id] = useState(null);
  const [data1, setData1] = useState(null);
  const [applicant, setApplicant] = useState(null);
  const [opt1, setOpt1] = useState(null);
  const [prof, setProf] = useState(null);
  const [uploadCV, setUploadCV] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [optSelect, setOptSelect] = useState(null);
  const [form, setForm] = useState({
    age: "",
    gender_id: "",
    city: "",
    studies_id: "",
    schedule_id: "",
    phone: "",
    contact_id: "",
  });
  const selectVacante = (i, applicantID) => {
    let opt = document.querySelector(`[id='${applicantID}']`)
    document.querySelector(`[id='${applicantID}']`).classList.add('bg-actv');

    setOptSelect(opt);
    if(optSelect !== null){
      optSelect.classList.remove('bg-actv')
    }
    setVacante(i);
    setApplicant(applicantID);
    setShowChat(false);
  };
  let app_id;
  const inputUserImg = useRef();

  const onChangeCV = (e) => {
    const readerC = new FileReader();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      readerC.addEventListener("loadstart", handleEvent);
      readerC.addEventListener("load", handleEvent);
      readerC.addEventListener("loadend", handleEvent);
      readerC.addEventListener("progress", handleEvent);
      readerC.addEventListener("error", handleEvent);
      readerC.addEventListener("abort", handleEvent);
      readerC.readAsDataURL(selectedFile);
    }
    function handleEvent(event) {
      if (event.type === "loadend") {
        setUploadCV(true);
        setForm({ ...form, photo_user_: inputUserImg.current.files[0] });
      }
    }
  };

 const ChatShow = () => {
    setShowChat(!showChat);
  };

  const editar = (pre) => {
    setShow(!show);
    setForm({
      age: pre.age,
      gender_id: pre.gender_id,
      city: pre.city,
      studies_id: pre.studies_id,
      schedule_id: pre.schedule_id,
      phone: pre.phone,
      contact_id: pre.id_contacto,
    });
  };

  // fetch DATA
  const fetchProfile = async () => {
    setLoading(true);
    try {
      const resp = await axios({
        url: baseUrlProfile,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      setProf(resp);
      setOpt1(resp);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
    }
  };
  const fetchStatus = async () => {
    setLoading(true);
    try {
      const resp = await axios({
        url: baseUrlStatus,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      setData1(resp);
      // setUser_id(resp.data.data.id);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
    }
  };
  useEffect(() => {
    fetchStatus();
    fetchProfile();
  }, []);
  const fetchData = async () => {
    const formData = new FormData();
    formData.append("age", form.age);
    formData.append("gender_id", form.gender_id);
    formData.append("city", form.city);
    formData.append("studies_id", form.studies_id);
    formData.append("schedule_id", form.schedule_id);
    formData.append("phone", form.phone);
    formData.append("contact_id", form.contact_id);

    if (form.photo_user_) {
      formData.append("photo_user_", form.photo_user_);
    }

    try {
      const resp = await axios({
        url: baseUrl,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: formData,
      });
      if (resp.data.success === 1) {
        setRes(resp);
        setLoading(true);
        setSuccess(true);

        setTimeout(() => {
          setLoading(false);
          setShow(false);
        }, 2500);
      }
      if (resp.data.success === 0) {
        setSuccess(null);
        setMsg(resp.data.message);
        setTimeout(() => {
          setSuccess(false);
        }, 3600);
      }
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
        window.location.reload();
      }, 4500);
    }
  };
  //  onchanges
  const onChangeAge = (e) => {
    setForm({ ...form, age: e.target.value});

  };
  const onChangeSchedule = (value) => {
    setForm({ ...form, schedule_id: value.value });
  };
  const onChangeGen = (value) => {
    setForm({ ...form, gender_id: value.value });
  };
  const onChangeCity = (value) => {
    setForm({ ...form, city: value.value });
  };
  const onChangeStudies = (value) => {
    setForm({ ...form, studies_id: value.value });
  };
  const onChangeContact = (value) => {
    setForm({ ...form, contact_id: value.value });
  };
  const onChangePhone = (e) => {
    setForm({ ...form, phone: e.target.value});

  };

  return (
    <>
      <div className="bg-back " style={{ height: "20px" }}></div>
      <div className="container-fluid  bg-back">
        <div className="row justify-content-center">
          <div className="col-10  bg-white bgShadow2 mb-3">
            <div className="row  justify-content-center">
              <div className="status-hola text-left col-10 mt-md-5 pb-md-3 animate__animated animate__fadeInDown">
                ¡HOLA DEPORTISTA!
              </div>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-3"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
                id="profile-img"
              >
                {show && opt1 !== null && (
                  <div className="button-wrapper-user animate__animated animate__fadeInUp animate__slow">
                    <span className="label">
                      SUBIR FOTO &nbsp; <FormUp color="white" /> <br />
                    </span>
                    <Form.Control
                      className="cFile "
                      type="file"
                      ref={inputUserImg}
                      onChange={(e) => onChangeCV(e)}
                    />
                  </div>
                )}
                <img
                  className="status-img-sabri bg-back animate__animated animate__fadeInDown"
                  src={
                    opt1 !== null && opt1.data.photo_user !== null
                      ? opt1.data.photo_user
                      : defaul
                  }
                  alt="Decatips"
                />
              </div>
              {!show && opt1 !== null && (
                <Col
                  md={6}
                  xs={12}
                  className=" animate__animated animate__zoomIn "
                >
                  <span className="status-name">
                    {opt1.data.name} {opt1.data.last_name}
                  </span>
                  <br />
                  <span className="status-email">{opt1.data.email}</span>
                  <br />
                  <br />
                  <p className="status-desc ">
                    {" "}
                    <b>Edad:</b> {opt1.data.age} años
                  </p>

                  <p className="status-desc ">
                    {" "}
                    <b> Género:</b> {opt1.data.gender}
                  </p>
                  <p className="status-desc ">
                    <b>Ciudad:</b> {opt1.data.city}
                  </p>
                  <p className="status-desc ">
                    <b>Estudios:</b> {opt1.data.studies}
                  </p>
                  <p className="status-desc ">
                    <b>Disponibilidad:</b> {opt1.data.schedule}
                  </p>
                  <p className="status-desc ">
                    <b>Teléfono:</b> {opt1.data.phone}
                  </p>
                  <p className="status-desc ">
                    <b>Método de contacto:</b> {opt1.data.contact}
                  </p>
                </Col>
              )}
              {show && (
                <Col
                  md={6}
                  xs={12}
                  className=" animate__animated animate__zoomIn "
                >
                  <span className="status-name">
                    {opt1.data.name} {opt1.data.last_name}
                  </span>
                  <br />
                  <span className="status-email">{opt1.data.email}</span>
                  <br />
                  <br />
                  <span className="status-desc">
                    <Row className="aling-center-status">
                      <Col sm="auto">
                        <b>Edad:</b>
                      </Col>
                      <Col sm={5} className="aling-col-left pb-1">
                        <Form.Control
                          type="number"
                          className="input-status"
                          name="age"
                           onChange={onChangeAge}
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </span>
                  <span className="status-desc">
                    {" "}
                    <Row className="aling-center-status">
                      <Col sm="auto">
                        <b>Género:</b>
                      </Col>
                      <Col sm={5} className="aling-col-left pb-2">
                        <SelectListGenStatus
                          className="input-status"
                          title="Schedule"
                          List="data-register"
                          deft={form.gender_id}
                          onChange={onChangeGen}
                        />
                      </Col>
                    </Row>
                  </span>

                  <span className="status-desc">
                    <Row className="aling-center-status">
                      <Col sm="auto">
                        <b>Ciudad:</b>
                      </Col>
                      <Col sm={5} className="aling-col-left pb-2">
                        <SelectListStoreStatus
                          className="input-status"
                          title="Schedule"
                          List="store-list"
                          deft={form.city}
                          onChange={onChangeCity}
                        />
                      </Col>
                    </Row>
                  </span>

                  <span className="status-desc">
                    <Row className="aling-center-status">
                      <Col sm="auto">
                        <b>Estudios:</b>
                      </Col>
                      <Col sm={5} className="aling-col-left pb-2">
                        <SelectListStudiesStatus
                          className="input-status"
                          title="Studies"
                          List="data-register"
                          deft={form.studies_id}
                          onChange={onChangeStudies}
                        />
                      </Col>
                    </Row>
                  </span>

                  <span className="status-desc">
                    <Row className="aling-center-status">
                      <Col sm="auto" className="align-middle">
                        <b>Disponibilidad:</b>
                      </Col>
                      <Col sm={5} className="aling-col-left align-middle">
                        <SelectListDispoStatus
                          className="input-status"
                          title="Schedule"
                          List="data-register"
                          deft={form.schedule_id}
                          onChange={onChangeSchedule}
                        />
                      </Col>
                    </Row>
                  </span>

                  <span className="status-desc">
                    <Row className="aling-center-status">
                      <Col sm="auto">
                        <b>Teléfono:</b>
                      </Col>
                      <Col sm={5} className="aling-col-left pb-2">
                        <Form.Control
                          type="text"
                          className="input-status"
                          name="phone"
                          value={form.phone}
                          onChange={onChangePhone}
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </span>
                  <span className="status-desc">
                    <Row className="aling-center-status">
                      <Col sm="auto">
                        <b>Método de contacto:</b>
                      </Col>
                      <Col sm={5} className="aling-col-left pb-2">
                        <SelectListContactStatus
                          className="input-status"
                          title="Contact"
                          List="data-register"
                          deft={form.contact_id}
                          onChange={onChangeContact}
                        />
                      </Col>
                    </Row>
                  </span>
                </Col>
              )}
              <div className="col-12 col-md-3 animate__animated animate__fadeIn animate__slow ">
                <div
                  className="btn-editar-status"
                  onClick={() => editar(opt1.data)}
                >
                  EDITAR &nbsp; <FormNext color="white" />
                </div>
              </div>
              {show && (
                <>
                  <div className="col-6  col-md-5 animate__animated animate__fadeInUp pt-md-5 mt-5">
                    <div
                      className="btn-cancelar-status"
                      onClick={() => editar(opt1.data)}
                    >
                      CANCELAR &nbsp; <FormClose color="white" />
                    </div>
                  </div>
                  <div className="col-6  col-md-5 animate__animated animate__fadeInUp  pt-md-5 mt-5">
                    <div
                      className="btn-actualizar-status"
                      onClick={() => fetchData()}
                    >
                      ACTUALIZAR &nbsp; <FormCheckmark color="white" />
                    </div>
                  </div>
                </>
              )}
            </div>
            {success === true && (
              <Form.Row className="justify-content-center pt-5">
                <button className="EnviarSuccess animate__animated animate__fadeIn   col-5">
                  <span className="label">
                    Sus datos se guardaron con éxito, gracias.
                  </span>
                </button>
              </Form.Row>
            )}
            {success === null && (
              <Form.Row className="justify-content-center pt-5">
                <button className="EnviarError animate__animated animate__fadeIn col-5">
                  <span className="label">
                    Ocurrio un error al enviar los datos.
                    <br />
                    <br />
                    {msg}
                  </span>
                </button>
              </Form.Row>
            )}
            <br />
            <br />
            <br />
            <br />
            <div className="row justify-content-center ">
              <div className="col-12 text-center status-vacante  mt-sm-5 mt-lg-4 mt-xl-3 mb-3  ">
              {
               data1 !== null && data1.data.data.length === 0 ? <>Sin postulaciones <br /><br /><br />&nbsp;</>:  "VACANTES"
              }
              </div>
            </div>
            <div className="row justify-content-center mt-4 " id="list-vacante">
              <div
                style={{
                  height:
                    data1 !== null && data1.data.data.length > 4 && "260px",
                }}
                className={
                  data1 !== null && data1.data.data.length > 4
                    ? "col-12  col-md-5 overflow-auto"
                    : "col-12 col-md-5"
                }
              >
                {data1 !== null &&
                  data1.data.data.map((jobs, i) => (
                    <div
                      id={jobs.applicant_id}
                      className={
                        data1 !== null && vacante === i
                          ? "col-12  text-justify statusVacantes "
                          : "col-12  text-justify statusVacantes "
                      }
                      key={i}
                      onClick={() => selectVacante(i, jobs.applicant_id)}
                    >
                      <div className="row justify-content-center">
                        <div className="col-12 my-1 ">{jobs.job_title}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {data1 !== null && data1.data.data.length !== 0 &&  (
              <div className="container-fluid maxWidthCirculos ptProgress">

                <div className="row maxWidthCirculos">
                  <div className="bar-status-desk">
                    <p className="p-can-rec">
                      Candidatura recibida
                      { data1.data.data[vacante].process_description &&
                       data1.data.data[vacante].process_description !== null &&
                       data1.data.data[vacante].process_description !== undefined &&
                      data1.data.data[vacante].process_description ===
                        "Candidatura recibida" && (
                        <>


                          <img
                            className="selectedBuso"
                            src={
                              prof !== null
                                ? -prof.data.sport_parent === "Montaña"
                                  ? montaña
                                  : prof.data.sport_parent ===
                                    "Carrera y Caminata"
                                  ? carrera
                                  : prof.data.sport_parent ===
                                    "Gimnasia y entrenamiento"
                                  ? gimnasia
                                  : prof.data.sport_parent === "Combate"
                                  ? combate
                                  : prof.data.sport_parent ===
                                    "Acuáticos y Subacuáticos"
                                  ? acuaticos
                                  : prof.data.sport_parent === "Olas y Arrastre"
                                  ? olas
                                  : prof.data.sport_parent === "Colectivos"
                                  ? colectivos
                                  : prof.data.sport_parent === "Raqueta"
                                  ? raqueta
                                  : prof.data.sport_parent === "Ciclismo"
                                  ? ciclismo
                                  : prof.data.sport_parent === "Urbanos"
                                  ? urbanos
                                  : prof.data.sport_parent === "Naturaleza"
                                  ? caza
                                  : prof.data.sport_parent === "Precisión"
                                  ? presicion
                                  : buso
                                : buso
                            }
                            alt="Decathlon"
                          />
                          <span className="selectedText">
                            {prof !== null && prof.data.sport_1.deporte}

                            <img
                              className="selectedlvl"
                              src={
                                prof !== null && prof.data.sport_1.nivel === 1
                                  ? lvl1
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 2
                                  ? lvl2
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>

                    {data1.data.data[vacante].process_description ===
                    "Candidatura recibida" ? (
                      <span className="dot dot_active"></span>
                    ) : (
                      <span className="dot dot_not_active"></span>
                    )}
                    <div
                      className="diag_1"
                      style={{ width: "150px", height: "30px" }}
                    ></div>

                    {data1.data.data[vacante].process_description ===
                    "Candidatura visualizada" ? (
                      <span className="dot_1 dot_active"></span>
                    ) : (
                      <span className="dot_1 dot_not_active"></span>
                    )}
                    <p className="p-can-vis">
                      Candidatura visualizada{" "}
                      {data1.data.data[vacante].process_description ===
                        "Candidatura visualizada" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={buso}
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            {prof !== null && prof.data.sport_1.deporte}

                            <img
                              className="selectedlvl"
                              src={
                                prof !== null && prof.data.sport_1.nivel === 1
                                  ? lvl1
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 2
                                  ? lvl2
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                    <div
                      className="diag_2"
                      style={{ width: "150px", height: "30px" }}
                    ></div>

                    <p className="p-can-con">
                      Candidato contactado{" "}
                      {data1.data.data[vacante].process_description ===
                        "Candidato contactado" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={buso}
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            {prof !== null && prof.data.sport_1.deporte}

                            <img
                              className="selectedlvl"
                              src={
                                prof !== null && prof.data.sport_1.nivel === 1
                                  ? lvl1
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 2
                                  ? lvl2
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                    {data1.data.data[vacante].process_description ===
                    "Candidatura contactado" ? (
                      <span className="dot dot_active"></span>
                    ) : (
                      <span className="dot dot_not_active"></span>
                    )}
                    <div
                      className="diag_1"
                      style={{ width: "150px", height: "30px" }}
                    ></div>

                    {data1.data.data[vacante].process_description ===
                    "Candidatura entrevistado" ? (
                      <span className="dot_1 dot_active"></span>
                    ) : (
                      <span className="dot_1 dot_not_active"></span>
                    )}
                    <p className="p-can-ent">
                      Candidato entrevistado{" "}
                      {data1.data.data[vacante].process_description ===
                        "Candidato entrevistado" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={buso}
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            {prof !== null && prof.data.sport_1.deporte}

                            <img
                              className="selectedlvl"
                              src={
                                prof !== null && prof.data.sport_1.nivel === 1
                                  ? lvl1
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 2
                                  ? lvl2
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                    <div
                      className="diag_2"
                      style={{ width: "150px", height: "30px" }}
                    ></div>

                    <p className="p-prop">
                      Propuesta de trabajo enviada{" "}
                      {data1.data.data[vacante].process_description ===
                        "Propuesta de trabajo enviada" && (
                        <>
                          <img
                            className="selectedBuso"
                            src={buso}
                            alt="Decatips"
                          />
                          <span className="selectedText">
                            {prof !== null && prof.data.sport_1.deporte}

                            <img
                              className="selectedlvl"
                              src={
                                prof !== null && prof.data.sport_1.nivel === 1
                                  ? lvl1
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 2
                                  ? lvl2
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                              }
                              alt="Decatips"
                            />
                          </span>
                        </>
                      )}
                    </p>
                    {data1.data.data[vacante].process_description ===
                    "Propuesta de trabajo enviada" ? (
                      <span className="dot dot_active"></span>
                    ) : (
                      <span className="dot dot_not_active"></span>
                    )}
                    <div
                      className="diag_1"
                      style={{ width: "150px", height: "30px" }}
                    ></div>

                    {data1.data.data[vacante].process_description ===
                    "Proceso de selección finalizado" ? (
                      <span className="dot_1 dot_active"></span>
                    ) : (
                      <span className="dot_1 dot_not_active"></span>
                    )}
                    <p className="p-fin">
                      Proceso de selección finalizado{" "}
                      {data1.data.data[vacante].process_description ===
                        "Proceso de selección finalizado" &&
                        opt1 !== null && (
                          <>
                            <img
                              className="selectedBuso"
                              src={buso}
                              alt="Decatips"
                            />
                            <span className="selectedText">
                            {prof !== null && prof.data.sport_1.deporte}

                              <img
                                className="selectedlvl"
                                src={
                                  prof !== null && prof.data.sport_1.nivel === 1
                                  ? lvl1
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 2
                                  ? lvl2
                                  : prof !== null &&
                                    prof.data.sport_1.nivel === 3
                                  ? lvl3
                                  : undefined
                                }
                                alt="Decatips"
                              />
                            </span>
                          </>
                        )}
                    </p>
                  </div>
                </div>
                <div className="row btn-chat d-none">
                  <button onClick={() => ChatShow()}>
                    CONTACTARME CON EL ENTREVISTADOR
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>{showChat === true && <Chat app_id={applicant} />}</div>
    </>
  );
};

export default Status;
