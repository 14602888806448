import axios from "axios";
import { BACKEND } from "../components/backend";
const baseUrl = BACKEND+"api/common/message-save";
const token =   localStorage.getItem('tokenUser');

export async function postForm(data) {
  try {
    const form_data = new FormData();
    form_data.append('message', data.message);
    form_data.append('applicant_id', data.props.app_id);
    const response = await axios({
      url: baseUrl,
      method: "POST",
      data: form_data,
      headers:{Authorization:"Bearer " + token}
    })
  } catch (e) {
  }
}

