import React from 'react';
import { Container, Col, Row, Carousel} from "react-bootstrap";
import Banner from "../components/images/banner_1.1.png";
import Banner1 from "../components/images/femme.png";
import Banner2 from "../components/images/image_9.png";
import Rectangle from "../components/images/rectangle.png";


const Diverso = () => {
    return (
      <div className="diverso" style={{backgroundColor: "#C4C4C4"}}>
        <Row>
          <div className="banner-div">
            <img src={Banner} alt="" />   
            <p className="banner-p">DIVERSIDAD</p>     
          </div>
        </Row>
        <Container style={{backgroundColor:"#fff"}} className="diverso-p">
          <Row style={{justifyContent: "center"}}>
            <div className="diverso-div">
              <p>En Decathlon creemos en la riqueza de la diversidad. Estamos convencidos de que  el deporte es un gran aliado para impulsar la educación e inclusión social, y mejorar el acceso al empleo para personas con discapacidad. <br /></p>
              <p>Del mismo modo, queremos que todas las personas, tengan las mismas posibilidades tanto en su desarrollo como en su trayectoria profesional.</p>
              <p>Somos conscientes del gran trabajo que nos queda por hacer en este sentido.</p>
              <p style={{color:"#0082C3"}}>¡Contamos contigo para que sumes desde dentro!</p>
            </div>
          </Row>
        </Container>
      </ div>
    );
  };

export default Diverso;
                        