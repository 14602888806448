import React, { useEffect, useState } from "react";
import "../styles/Loader.css";
export const LoaderDel = (resultado) => {
  const [count, setCount] = useState(null);
  const [loads, setLoads] = useState()
 

  useEffect(() => {
    setTimeout(() => {
     
       if (resultado.resultado.data.success === 1) {
        setLoads(true)
        setCount(
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">{resultado.resultado.data.message}</h4>
           <p>Vacante actualizada correctamente. <br /></p>
          </div>)
        
      }
        if (resultado.resultado.data.success === 0){
          setLoads(false)
          setCount(
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">{resultado.resultado.data.message}</h4>
           
          </div>)
        }
        
    }, 700);
  }, []);
  return (
    <>
    {
      count === null ? 
      <div className="row justify-content-center ">
        <div className="col-12  text-center " style={{ height: "35em" }}>
          <div className="lds-roller  " style={{ marginTop: " 250px " }}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      :
      loads === true ? count
      : 
      count
    }


      
    </>
  );
};
