import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img1 from "../components/images/img1.png";
import img2 from "../components/images/img2.png";
import img3 from "../components/images/img3.png";
import img4 from "../components/images/img4.png";
import img5 from "../components/images/img5.png";
import { Next, Previous } from "grommet-icons";
import "../styles/dots.css";
function Carrera() {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Next
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
        color="#fff"
        size="large"
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Previous
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
        color="#fff"
        size="large"
      />
    );
  }

  const settings = {
    className: "center",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="carrera-bg-img">
      <div className="bg-color-transparent">
        <div className="py-carrera-1">
          <div className="h3-carrera">TU CARRERA EN</div>
          <div className="h3-deca">DECATHLON</div>
        </div>
        <div className="py-carrera-2">
          <div className="carrera-txt-1">
            En Decathlon, tú escribes el recorrido profesional que quieres
            vivir. El colaborador es el pricipal motor de su propio desarrollo,
            y ponemos todo de nuestra parte para acompañarte en tu objetivos.
          </div>
          <br />
          <div className="carrera-txt-2 pb-carrera-1">
            ¡Te mostramos algunos ejemplos de cómo vivimos el deporte de una
            forma diferente!
          </div>
          <div className="Slick-Slider">
            <Slider {...settings}>
              <div className="iframe-container2">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/U5W34MpMgMM"
                  title="YouTube video player"
                  frameBorder="{0}"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="iframe-container2">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/r__XTTauh2k?start=8"
                  title="YouTube video player"
                  frameBorder="{0}"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="iframe-container2">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/799iJRGA8sQ?start=6"
                  title="YouTube video player"
                  frameBorder="{0}"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="iframe-container2">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/nY13XhNa1B4"
                  title="YouTube video player"
                  frameBorder="{0}"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carrera;
