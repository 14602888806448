import React, { useState } from "react";
import { useFetch } from "../../../hooks/useFetch";

const SelectListSports = ({  url, cat, onChange, deft, dtxt, setSportName }) => {
  const [select, setSelect] = useState(null);

  const { data } = useFetch(url);

  if (!data) return null;

  let options = [];

  options = data.data[`${cat}`];

  const noCerrar = (e) => {
    if (cat === "cat_sports") {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const handleLvl = (id, e, lvl, descrip) => {
    e.preventDefault();
    onChange(id);
    setSelect(descrip);
    setSportName(descrip);
  };

  const bugfix = (name, e, index) => { // fix clic category sport
      let id_sport_sub = options[index].sub_sports.find(sub => sub.description === name)?.id_sport_sub;
      handleLvl(id_sport_sub, e, 3, name);
  }

  return (
    <>
      <span
        id="dLabel"
        role="button"
        data-toggle="dropdown"
        className="cSelect nav-link dropdown-toggle"
        data-target="#"
        aria-haspopup="true"
        aria-expanded="false"
      >
               {select === null ?   dtxt: select} <span className="caret"></span>

      </span>
      <ul
        className="dropdown-menu multi-level "
        role="menu"
        aria-labelledby="navbarDropdownMenuLink"
      >
        {options.map((category, index) => (
          <li key={category.sport_id}>
            <span className="dropdown-item  d-inline-flex  mt-auto">
              <div className="icon-link ">
                <img src={category.sport_image} alt="" />
              </div>

              <ul
                key={category.sport_id}
                className="dropdown-submenu dropdown-item"
              >
                <span
                  className="dropdown-item item-text"
                  onClick={(e) => {
                    // noCerrar(e);
                    bugfix(category.sport_name, e, index);
                  }}
                >
                  {category.sport_name}
                </span>{" "}
                {/* <ul
                  className="dropdown-menu multi-level "
                  role="menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  {category.sub_sports.map((subCategory, i) => {
                      if (category.sub_sports.length - 2 === i) {
                        <li
                          key={subCategory.id_sport_sub}
                          className="dropdown-submenu mx-2"
                        >
                          <span
                            className="dropdown-item item-text pl-3"
                            onClick={(e) =>
                              handleLvl(
                                subCategory.id_sport_sub,
                                e,
                                3,
                                subCategory.description
                              )
                            }
                          >
                            {subCategory.description}
                          </span>
                        </li>
                      } 
                  })}
                </ul> */}
              </ul>
            </span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SelectListSports;
