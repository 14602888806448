import axios from "axios";
import { useState, useEffect } from "react";
import { BACKEND } from "../components/backend";

export const useShowList = (List) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const baseUrl = BACKEND +'api/'+ List;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios({
          url: baseUrl,
          method: "GET",
          //   headers: { Authorization: "Bearer " + token },
        });
        setData(res.data.data);
        setError(null);
      } catch (error) {
        if (signal.aborted) {
          setError(error);
        }
      } finally {
        if (signal.aborted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => abortController.abort();
  }, [List]);
  return { data, error, loading };
};
