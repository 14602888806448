import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  list-style: none;
  height: 50px;
  text-decoration: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  -webkit-text-fill-color: #9acd3200;
  -webkit-text-stroke: 1px white;
  letter-spacing: 1px;

  &:hover {
    // background: #0082c3;
    // border-left: 4px solid #fff;
    cursor: pointer;
    font-weight: 400;
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke: 1px white;

    transition: all 0.1s linear;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  height: 40px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;

  &:hover {
    cursor: pointer;
    font-weight: 500;
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke: 0px white;

    transition: all 0.1s linear;
  }
`;

const SubMenu = ({ item, isModalOpen, setModalOpen }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);
  const changeClose = () => {
    if (item.title !== "Somos Deporte") {setModalOpen(false);}
  };

  return (
    <>
      <SidebarLink to={item.path || "#"} onClick={item.subNav && showSubnav}>
        <div onClick={changeClose}>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink onClick={changeClose} to={item.path || "#"} key={index}>
              {item.icon}
              <SidebarLabel onClick={() =>setModalOpen(false)}>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
