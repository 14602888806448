import React from 'react'
import decatipsG from '../components/images/decatipsG.png';
import decatipschico from '../components/images/decatipschico.png';
function Decatips() {
    return (
        <>
        <div className="decatips-container" >
          <img className="decatips-imgG" src={decatipsG} alt="Decatips"/>  
          <img className="decatips-imgS" src={decatipschico} alt="Decatips"/>  
        </div>
        </>
    )
}

export default Decatips;

