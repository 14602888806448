import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext';
import Status from '../../pages/Status';

const StatusUserRoute = () => {
const user = useContext(AuthContext)
    return (
            <Switch>
            
            <Route path="/status"  component={Status} isAutenticated={ user.user}  />
            
            </Switch>
    )
}

export default StatusUserRoute
