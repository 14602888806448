import React, { useState, useEffect } from 'react';
import Select, { components } from "react-select";

const CustomSelect = ({ title, titleColor, options, value, setValue }) => {

    const customStyles = {
        option: (provided) => ({
            ...provided,
            padding: 15,
            fontSize: 16,
            fontWeight: 600,
            zIndex: 9999999999,
        }),
        control: (base, state) => ({
            ...base,
            padding: 5,
            color: "#000000",
        }),

        placeholder: (provided) => ({
            ...provided,
            color: "#C4C4C4",
        }),
    };

    return (
        <>
            <b style={{ color: titleColor }} className="title-jobs">{title}</b>
            <Select
                value={value}
                styles={customStyles}
                className=""
                options={options}
                placeholder={"Seleccione una opción..."}
                onChange={(value) => setValue(value)}
                isDisabled={options.length === 0 ? true : false}
            />
        </>
    )
}

export default CustomSelect
