import React, { useEffect } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
const Logger = ({history}) => {
  const { dispatch } = useContext(AuthContext);

  let {search}= useLocation();
   let query = new URLSearchParams(search);
   let authorization = query.get("authorization");
   let refresh = query.get("refresh");
   if(authorization !== null ){
     localStorage.setItem('token', authorization);
     localStorage.setItem('refresh', refresh);



       history.replace("/cms/auth/admin-vacantes")

   }else{
    dispatch({
      type: types.adminlogout,
    });
    localStorage.clear();
    history.replace("/")
      window.location.reload();
   }
  return (
    <>
     
    </>
  );
};

export default Logger;
