import React from 'react';
import ApplicantItem from './ApplicantItem';
import { Accordion } from "react-bootstrap";

const ApplicantsList = ({ applicants, clearFilter, applyJob, jobsApply, stores, jobs, handleShowComments }) => {

    return (
        <>
            { applicants.length > 0 ?
                <>
                    { applicants.map((applicant, index) => {
                        return (
                            <Accordion key={index}>
                                <ApplicantItem
                                    applicant={applicant}
                                    applyJob={applyJob}
                                    jobsApply={jobsApply}
                                    stores={stores}
                                    jobs={jobs}
                                    handleShowComments={handleShowComments}
                                />
                            </Accordion>
                        )
                    }) }
                </>
            :
                <h3 className="text-center py-5" style={{ backgroundColor: "#C4C4C4" }}>
                    No hay resultados de la busqueda
                    <div className="mt-3">
                        <span className="btn-limpiar px-3 mb-5 cursor-pointer" onClick={clearFilter}>
                            Limpiar Filtros
                        </span>
                    </div>
                </h3>
            }
        </>


    )
}

export default ApplicantsList
