import React from "react";
import Instagram from "../images/instagram.svg";
import Facebook from "../images/facebook.svg";
import Youtube from "../images/youtube.svg";
import Linkedin from "../images/linkedin.svg";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <span className="Oleft">
          © 2024 Decathlon. Todos los derechos reservados <br />
          Aviso legal | Datos personales
        </span>
        <span className="Ocenter">
          <a
            href="https://www.decathlon.com.mx/"
            target="_blank"
            rel="noreferrer"
          >
            decathlon.com.mx
          </a>
          <b style={{ display: "block" }}>¡Conócenos!</b>
          <a href="https://www.instagram.com/decathlon_mx/?hl=en">
            <img src={Instagram} alt="Instagram" className="icon" />
          </a>
          <a href="https://www.facebook.com/DecathlonMexico/">
            <img src={Facebook} alt="Facebook" className="icon" />
          </a>
          <a href="https://www.youtube.com/channel/UCLBtITHHNjaevRunUk3sT0g">
            <img src={Youtube} alt="Youtube" className="icon" />
          </a>
          <a href="https://www.linkedin.com/company/decathlon-mexico/mycompany/">
            <img src={Linkedin} alt="Linkedin" className="icon" />
          </a>
        </span>
        <span className="Oright"></span>
      </footer>
    </>
  );
};

export default Footer;
