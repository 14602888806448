import React from "react";
import surf from "../components/images/surf.jpg";
import cuadroNaranja from "../components/images/cuadroNaranja.png";
import { Container, Col, Row } from "react-bootstrap";
const Cultura = () => {
  return (
    <>
      <div className="cultura ">
        <div className="bg-color-transparent flex-cntnt-cult">
          <div className="flex-cntnt">
            <Row>
              <Col xs={12} md={6}>
              <div className="cultura--one">
                <h3 className="h3-cultura hp-cultura">
                  NUESTRO SENTIDO{" "}
                  <img src={cuadroNaranja} className="video-1" alt="" />
                </h3>
                <p className="hp-cultura">MOVE PEOPLE THROUGH THE WONDERS OF SPORT</p>
              </div>
              </Col>
              <Col md={6}>
                <div className="ocultar900">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/PPsfI4w5bmk?si=lCwis_T2UyNf2rC8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              </Col>
            </Row>
            
          </div>
          <div className="flex-cntnt aling-centermin">
            <Row>
              <Col xs={12} md={6}>
              <div className="ocultar900">
                <img src={surf} alt="" style={{width: "70%"}}/>
              </div>
              </Col>
              <Col md={6}>
              <div className="cultura--two">
                <h3 className="h3-cultura">
                  <img src={cuadroNaranja} className="video-2" alt="" /> NUESTRO POSICIONAMIENTO
                </h3>
                <p className="hp-cultura">
                  MAKE SPORT YOURS
                </p>
              </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="cultura-white">
          <div className="flex-cntnt variant-cntnt-colab">
            <div className="item-colaboradores">
              <span className="txt-h1">
                +700 <br />
              </span>
              <span className="txt-h2">COLABORADORES</span>
              <span className="txt-h3">
                <br /> EN MÉXICO
              </span>
            </div>
            <div className="item-colaboradores">
              <span className="txt-h3">
                MÁS DE
                <br />
              </span>
              <span className="txt-h1">
                105,000
                <br />
              </span>
              <span className="txt-h2">COLABORADORES</span>
              <span className="txt-h3">
                <br /> ALREDEDOR DEL MUNDO
              </span>
            </div>
            <div className="item-colaboradores">
              <span className="txt-h1">
                15
                <br />
              </span>
              <span className="txt-h2">TIENDAS</span>
              <span className="txt-h3">
                <br /> EN LA REPÚBLICA MEXICANA
              </span>
            </div>
            <div className="item-colaboradores">
              <span className="txt-h1">
                1
                <br />
              </span>
              <span className="txt-h2">CENTRO LOGÍSTICO</span>
              <span className="txt-h3">
                <br /> EN MÉXICO
              </span>
            </div>
            <div className="item-colaboradores">
              <span className="txt-h1">
                + 40
                <br />
              </span>
              <span className="txt-h2">PRODUCTOS HECHOS</span>
              <span className="txt-h3">
                <br /> EN MÉXICO
              </span>
            </div>
          </div>
        </div>
        <div >
          <div className="bg-color-transparent flex-cntnt-cult">
            <div className="nuestros4 py-1">
              <h3>NUESTROS 4 VALORES</h3>
            </div>

            <div className="flex-cntnt-val variant-cntnt-colab">
              <div className="item-colaboradores">
                <span className="gigant-num">01</span>
                <div className="txt-negative">
                  <span className="txt-naranja">
                    VITALIDAD <br />
                  </span>

                  <span className="txt-white-val">
                    Ser positivo y estar <br />
                    lleno de energía.
                  </span>
                </div>
              </div>
              <div className="item-colaboradores">
                <span className="gigant-num">02</span>
                <div className="txt-negative">
                  <span className="txt-naranja">
                    RESPONSABILIDAD <br />
                  </span>

                  <span className="txt-white-val">
                    Me comprometo con objetivos <br /> alcanzables.
                  </span>
                </div>
              </div>
              <div className="item-colaboradores">
                <span className="gigant-num">03</span>
                <div className="txt-negative">
                  <span className="txt-naranja">
                    GENEROSIDAD <br />
                  </span>

                  <span className="txt-white-val">
                    Juego en equipo, <br /> ayudo cuando es <br /> necesario.
                  </span>
                </div>
              </div>
              <div className="item-colaboradores">
                <span className="gigant-num">04</span>
                <div className="txt-negative">
                  <span className="txt-naranja">
                    AUTENTICIDAD <br />
                  </span>

                  <span className="txt-white-val">
                    Se trata de ser fiel <br /> a uno mismo y a <br /> los demás.
                  </span>
                </div>
              </div>
            </div>

            <div className="py-1   ">
              <div className="nuestros4 py-1">
                <h3>CULTURA DE EMPRESA</h3>
              </div>
              <div className="txt-cult-empresa py-1 pb-2">
                <p>
                  En Decathlon amamos el deporte como un estilo de vida innovador.
                  Creemos en la riqueza de la diversidad y ponemos todo de nuestra
                  parte para que cada colaborador desarrolle su carrera
                  profesional en aquello que le apasiona.
                  <br />
                  {/* El siguiente corto, está grabado por colaboradores de Decathlon
                  alrededor del mundo. */}
                </p>
              </div>
              {/* <div className=" iframe-cult">
                <iframe
                  className="responsive-iframe"
                  src="https://www.youtube.com/embed/EGXv5JF1XKE"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cultura;
