import React from "react";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    class: "font-rob",
  },
  {
    title: "¿Quienes somos?",
    path: "/cultura",
  },
  {
    title: "Somos Deporte",

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Accesible ",
        path: "/accesible",
      },
      {
        title: "Sustentable",
        path: "/sustentable",
      },
      {
        title: "Innovador",
        path: "/digital",
      },
      {
        title: "Diverso",
        path: "diverso",
      },
      // {
      //   title: 'Sustentabilidad',
      //   path: '/sustentabilidad',
      // }
      // {
      //   title: 'Reports 2',
      //   path: '/reports2',
      //   icon: <IoIcons.IoIosPaper />,
      //   cName: 'sub-nav'
      // },
      // {
      //   title: 'Reports 3',
      //   path: '/somos-deporte/reports3',
      //   icon: <IoIcons.IoIosPaper />
      // }
    ],
  },
  {
    title: "Tu carrera en Decathlon",
    path: "/carrera",
  },
  {
    title: "Trabaja en Decathlon",
    path: "/team",
  },
  // {
  //   title: "Cultura",
  //   path: "/cultura",
  // },
];
