import { types } from "../types/types";
// const state = {
//   name: "Carlos",
//   logged: true,
// };
export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case types.login:
      return {
        ...action.payload,
        logged: true,
      };

    case types.logout:
      return {
        logged: false,
      };
    case types.adminlogin:
      return {
        ...action.payload,
        isAdmin: true,
      };
    case types.adminlogout:
      return {
        ...action.payload,
        isAdmin: false,
      };

    default:
      return state;
  }
};
