import React from "react";
import { useFetch } from "../hooks/useFetch";
import Select, { components } from "react-select";

const SelectList = ({ title, url, cat, onChange, name, blur }) => {
  const { Option } = components;
  
  const CustomSelectOption = (props) => (
    <Option {...props}>{props.data.label}</Option>
  );

  const CustomSelectValue = (props) => <div>{props.data.label}</div>;

  const { data } = useFetch(url);

  if (!data) return null;
  let options = [];
  let list = [];
  options = data.data[`${cat}`];
  // list = options.filter((opt) => opt !== "cat_sports");
  list = options.map((category) => ({
    value: category.id,
    label: category.description,
  }));

  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 15,
      fontSize: 18,
      fontWeight: 600,
    }),
    control: (base, state) => ({
      ...base,
      padding: 5,
      color: "#000000",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#C4C4C4",
    }),
  };

  return (
    <>
      <Select
        name={name}
        styles={customStyles}
        className=" "
        options={list}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
        }}
        defaultValue="a"
        placeholder={title}
        onChange={onChange}
        onBlur={blur}
      />
    </>
  );
};

export default SelectList;
