import React from "react";
// import { useFetch } from "../hooks/useFetch";
import Select, { components } from "react-select";
import { useShowList } from "../../../services/getShowStoreListB";

const SelectListJobs = ({ List, onChange, deft, dis  }) => {
  const { Option } = components;

  const CustomSelectOption = (props) => (
    <Option {...props}>{props.data.label}</Option>
  );

  const CustomSelectValue = (props) => <div>{props.data.label}</div>;

  const { data } = useShowList(List);
  if (!data) return null;
  let options = [];
  let list = [];
  options = data;
  // list = options.filter((opt) => opt !== "cat_sports");
    
  list = options.map((category) => ({
    value: category.job_name_id,
    label: category.description,
  }));

  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 15,
      fontSize: 18,
      fontWeight: 600,
    }),
    control: (base, state) => ({
      ...base,
      padding: 5,
      color: "#000000",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#C4C4C4",
    }),
  };
  let place
  if (deft) {
   place = list.filter(option => option.value === deft)
  }
  
  return (
    <>
      <Select
        isDisabled={dis === true ? true : false}

      // value={deft &&  list.filter(option => option.value === deft)}
        styles={customStyles}
        className=" "
        options={list}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
        }}
        defaultValue="a"
        onChange={onChange}
        placeholder={deft ?  place[0].label : "Seleccione una opción" }
      />
    </>
  );
};

export default SelectListJobs;
