import React from "react";
import "../styles/Loader.css";
export const Loader = () => {
 

  return (
    <>
      <div className="row justify-content-center ">
        <div className="col-12  text-center " style={{ height: "35em" }}>
          <div className="lds-roller  " style={{ marginTop: " 250px " }}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};
