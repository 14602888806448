import { useContext } from "react";
import { HashRouter as Switch } from "react-router-dom";
import CmsRoutes from "../Routes/cmsRoute";
import LoginAdmin from "../login";
import PrivateRoute from "./PrivateRoute";
import { AuthContext } from "../../auth/AuthContext";
import PublicRoute from "./PublicRoute";
import StatusUserRoute from "./statusUserRoute";

function AppRouter() {
  const { user } = useContext(AuthContext);
  return (
    <div className="content">
      <Switch>
      <PrivateRoute
          path="/cms"
          component={CmsRoutes}
          isAutenticated={user.isAdmin}
        />
        <PublicRoute
          path="/login"
          exact
          component={LoginAdmin}
          isAutenticated={user.logged}
        />
        <PrivateRoute
          path="/status"
          component={StatusUserRoute}
          isAutenticated={user.logged}
        />
        
      </Switch>
    </div>
  );
}

export default AppRouter;
