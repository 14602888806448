import { FormNext } from "grommet-icons";
import React, { useState } from "react";
import { useRef } from "react";
import { Col, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { LoaderChico } from "../components/LoaderChico";
import { useParams } from "react-router-dom";
import { BACKEND } from "../components/backend";

const Recuperar = () => {
  const { tkn_restore } = useParams();

  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    token: tkn_restore,
    password: "",
    password_confirmation: "",
  });
  const [captchaValido, setCaptchaValido] = useState(null);
  const [loader, setLoader] = useState(null);
  const [res, setRes] = useState(null);
  const [success, setSuccess] = useState(null);
  const [msg, setMsg] = useState(null);

  const captcha = useRef(null);

  const exp = {
    password: /^[a-zA-Z0-9\_\-]{8,12}$/,
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };
  let lower;
  const handleChange = (e) => {
    const { name, value } = e.target;
    lower = value.toLowerCase();
    setForm({ ...form, [name]: lower.trim() });
  };
  const handleBlur = (e) => {
    ///// SETEO ERRORES

    switch (e.target.name) {
      case "password":
        if (exp.password.test(form.password.trim())) {
          if (form.password !== form.password_confirmation) {
            setErrors({ ...errors, coinciden: true });
          }
          if (form.password === form.password_confirmation) {
            setErrors({ ...errors, coinciden: false, password: false, password_confirmation: false });
          }
        }
        if (!form.password.trim()) {
          setErrors({ ...errors, password: true });
        } else if (!exp.password.test(form.password.trim())) {
          setErrors({ ...errors, password: true });
        }

        break;
      case "password_confirmation":
        if (exp.password.test(form.password_confirmation.trim())) {
          if (form.password !== form.password_confirmation) {
            setErrors({ ...errors, coinciden: true });
          }
          if (form.password === form.password_confirmation) {
            setErrors({ ...errors, coinciden: false, password: false, password_confirmation: false });
          }
        }
        if (!form.password_confirmation.trim()) {
          setErrors({ ...errors, password_confirmation: true });
        } else if (!exp.password.test(form.password_confirmation.trim())) {
          setErrors({ ...errors, password_confirmation: true });
        }

        break;
      default:
        break;
    }
  };
  const handleCaptcha = () => {
    if (captcha.current.getValue()) {
      setErrors({ ...errors, captcha: false });
    }
  };
  /// MATCH

  /////// SUBMIT
  const baseUrl =BACKEND+"api/restore_password";

  const fetchData = async () => {
    try {
      const resp = await axios({
        url: baseUrl,
        method: "POST",
        data: form,
      });
      setRes(resp);
      if (resp.data.success === 1) {
        setTimeout(() => {
          setLoader(false);
          setSuccess(true);
          setCaptchaValido(true);
        }, 2500);
        setCaptchaValido(false);
        setMsg(resp.data.message);
      }
      if (resp.data.success === 0) {
        setTimeout(() => {
          setLoader(false);
          setSuccess(false);
          setCaptchaValido(true);
        }, 2500);
        setMsg(resp.data.message);
        setErrors({ ...errors, err: true });
        setTimeout(() => {
          setSuccess(null);
          setErrors({ ...errors, err: false });
          setCaptchaValido(false);
        }, 6000);
      }
    } catch (error) {
    } finally {
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (captcha.current.getValue()) {
      //  setLoader(true);
      // setCaptchaValido(true);
      if (errors.coinciden === false && errors.password === false && errors.password_confirmation=== false && tkn_restore !== '') {
         fetchData();
        setLoader(true);
      } else if (form.password === 0 || form.password === "") {
        setErrors({ ...errors, password: true });
      } else if (form.password_confirmation === 0 || form.password_confirmation === "") {
        setErrors({ ...errors, password_confirmation: true });
      }
    } else {
      // setLoader(true);
      //  setCaptchaValido(true);
      setErrors({ ...errors, captcha: true });
    }
  };
  if (!tkn_restore) {
  } else {
  }
  return (
    <>
      <div className="container animate__animated animate__fadeIn animate__slow">
        {!loader ? (
          !captchaValido && (
            <>
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <br />
                  <br />
                  <br />
                  <span className="forgot-crear ">CREA TU CONTRASEÑA</span>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-8 text-center">
                  <br />
                  <br />
                  <span className="forgot-desc text-center ">
                    ¡Hola Deportista! <br />
                    Aquí puedes crear tu contraseña, te llegará a tu correo
                    electrónico la confirmación de contraseña.
                  </span>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-5 col-sm-10 mt-5">
                  <Form onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group as={Col} sm={12} controlId="formGridnombre">
                        <Form.Label className="formTitl">
                          Contraseña:{" "}
                          <span
                            className="text-muted"
                            style={{ fontWeight: "400", fontSize: "17px" }}
                          >
                            &nbsp; (Mínimo 8 caracteres)
                          </span>
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={12} controlId="formGridnombre">
                        <Form.Control
                          maxLength="12"
                          type="password"
                          className="stylePlace stylePlacer"
                          name="password"
                          style={{
                            textTransform: "lowercase",
                            borderColor: errors.password == true && "#ff0000",
                          }}
                          values={form.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                        />
                        {errors.password == true && (
                          <p className="text-danger text-errors">
                            {" "}
                            El campo Contraseña es requerido.
                          </p>
                        )}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={12} controlId="formGridnombre">
                        <Form.Label className="formTitl">
                          Confirmar contraseña:{" "}
                          <span
                            className="text-muted"
                            style={{ fontWeight: "400", fontSize: "17px" }}
                          >
                            &nbsp; (Mínimo 8 caracteres)
                          </span>
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={12} controlId="formGridnombre">
                        <Form.Control
                          maxLength="12"
                          type="password"
                          className="stylePlace stylePlacer"
                          name="password_confirmation"
                          style={{
                            textTransform: "lowercase",
                            borderColor: errors.password_confirmation == true && "#ff0000",
                          }}
                          values={form.password_confirmation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                        />
                        {errors.password_confirmation == true && (
                          <p className="text-danger text-errors">
                            {" "}
                            El campo Confirmar contraseña es requerido.
                          </p>
                        )}
                        {errors.coinciden == true && (
                          <p className="text-danger text-errors">
                            {" "}
                            Las contraseñas no coinciden.
                          </p>
                        )}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group>
                        <ReCAPTCHA
                          ref={captcha}
                          sitekey="6LdSd-AnAAAAAAe-99f6dnAuoRfXU76qC9QB3LXI"
                          onChange={handleCaptcha}
                        />
                        {errors.captcha == true && (
                          <p className="text-danger text-errors">
                            {" "}
                            Valide el captcha.
                          </p>
                        )}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="justify-content-center mt-4 mb-5">
                      <button
                        className="forgot-enviar animate__animated animate__fadeIn text-center text-white "
                        type="submit"
                      >
                        <span className="label ">
                          ENVIAR &nbsp; <FormNext color="white" />
                        </span>
                      </button>
                    </Form.Row>
                  </Form>
                </div>
              </div>
            </>
          )
        ) : (
          <div className="row justify-content-center my-5 py-5">
            <div className="col-12 text-center my-5">
              <br />
              <br />
              <br />
              <LoaderChico />
            </div>
          </div>
        )}
        {success && (
          <div className="row justify-content-center my-5 py-5">
            <div className="col-12 text-center my-5">
              <br />
              <br />
              <br />
              <span className="forgot-crear ">
                Gracias <br />
                <br />
              </span>
              <span style={{ fontSize: "1.3em", color: "gray" }}>
              </span>
              <span style={{ fontSize: "1.3em", color: "gray" }}>{msg}</span>
            </div>
          </div>
        )}
        {success === false && (
          <Form.Row>
            <br />
            <br />
            <br />
            <button className="EnviarError animate__animated animate__fadeIn my-5">
              <span className="label">
                Ocurrio un error al enviar los datos.
                <br />
                {msg}
              </span>
            </button>
          </Form.Row>
        )}
      </div>
    </>
  );
};

export default Recuperar;
