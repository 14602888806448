import React from "react";
import { useFetch } from "../hooks/useFetch";
import Select, { components } from "react-select";

const SelectCity = ({ title, url,  onChange }) => {
  const { Option } = components;
  
  const CustomSelectOption = (props) => (
    <Option {...props}>{props.data.label}</Option>
  );

  const CustomSelectValue = (props) => <div>{props.data.label}</div>;

  const { data } = useFetch(url);

  if (!data) return null;
  let options = [];
  let list = [];
  options = data.data;
  // list = options.filter((opt) => opt !== "cat_sports");
  let stores = data.data.map((str) => ({
    value: str.store_location,
    label: str.store_location
    }));
  const filteredStores = stores.reduce((acc, current) => {
    const x = acc.find((item) => item.label === current.label);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 15,
      fontSize: 18,
      fontWeight: 600,
    }),
    control: (base, state) => ({
      ...base,
      padding: 5,
      color: "#000000",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#C4C4C4",
    }),
  };

  return (
    <>
      <Select
        styles={customStyles}
        className=" "
        options={filteredStores}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
        }}
        defaultValue="a"
        placeholder={title}
        onChange={onChange}
      />
    </>
  );
};

export default SelectCity;
