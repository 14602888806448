import React, { useEffect, useState } from "react";
import low from "./images/delete.png";
import close from "./images/cancel.png";
import send from "./images/send.png";
import { postForm } from "../services/postMessage";
import $ from "jquery";
import axios from "axios";
import { BACKEND } from "./backend";

export const Chat = (props) => {
  let id = props.app_id;

  const token = localStorage.getItem("tokenUser");
  const baseUrl = BACKEND + "api/common/message-list";
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    message: "",
    result: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postForm({ ...form, props });
    setTimeout(() => {
      fetchData();
    }, 1000);
    setForm({ ...form, message: "" });
  };

  const closeWin = () => {
    document.querySelector("#contenedor-chat").style.display = "none";
  };

  const minWin = () => {
    $(".box").slideToggle();
    var objDiv = document.getElementById("chat");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await axios({
        url: baseUrl + "/" + id,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      setData(res.data.data);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  let intervalID;

  useEffect(() => {
    if (props.app_id !== null) {
      fetchData();
      intervalID = setInterval(() => {
        fetchData();
      }, 4000);
      return () => {
        clearInterval(intervalID);
      };
    }
  }, []);

  let render;
  let name_talk;
  if (data !== null) {
    name_talk = data.talking_with;
    var element = document.getElementById("chat");
    element.scrollTop = element.scrollHeight;
    render = data.messages.map((idx, index) => {
      return (
        <div key={index}>
          <span style={{ color: "#000", fontSize: "14px", fontWeight: "400" }}>
            {idx.nombre}
          </span>
          <br />
          <span
            style={{ color: "#001018", fontSize: "12px", fontWeight: "300" }}
          >
            {idx.message}
          </span>
          <span
            style={{
              float: "right",
              fontSize: "10px",
              fontStyle: "italic",
              fontWeight: "400",
              marginTop: "10px",
            }}
          >
            Recibido {idx.fecha}
          </span>
        </div>
      );
    });
  }
  if (document.querySelector('[name="message"]') !== null) {
    if (document.querySelector('[name="message"]').value === "") {
      document
        .querySelector('[name="enviar"]')
        .setAttribute("disabled", "true");
    } else {
      document
        .querySelector('[name="enviar"]')
        .removeAttribute("disabled", "false");
    }
  }

  return (
    <div
      id="contenedor-chat"
      className="fixed-bottom"
      style={{
        display: "block",
      }}
    >
      <div
        style={{
          backgroundColor: "#0082C3",
          color: "#fff",
          borderRadius: "15px 15px 0 0",
          height: "2.5em",
          display: "flex",
          alignItems: "center",
          padding: "15px",
          fontWeight: "700",
        }}
        onClick={minWin}
      >
        {!name_talk || name_talk === "" || name_talk === null
          ? "Decathlon"
          : name_talk}
        <img
          src={low}
          style={{
            width: "12px",
            left: "7em",
            position: "relative",
            cursor: "pointer",
          }}
          alt=""
          className="button"
        />
        <img
          src={close}
          style={{
            width: "12px",
            left: "8em",
            position: "relative",
            cursor: "pointer",
          }}
          alt=""
          onClick={closeWin}
        />
      </div>
      <div id="caja-chat" className="box">
        <div id="chat">
          <div id="datos-chat">{render}</div>
        </div>
      </div>
      <form className="form-chat box" onSubmit={handleSubmit}>
        <input
          type="text"
          name="message"
          value={form.message}
          onChange={handleChange}
          placeholder="ingresa tu mensaje"
          style={{ width: "70%", border: "none" }}
        ></input>
        <button
          type="submit"
          name="enviar"
          value="Enviar"
          style={{ background: "none", border: "none" }}
        >
          <img src={send} style={{ width: "80%" }} alt="" />
        </button>
      </form>
    </div>
  );
};
export default Chat;
