import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import logo from "./images/search.png";
import LogoDecathlon from "./images/DecathlonLogo.png";
import User from "./images/user.png";
import useOnClickOutside from "../hooks/useSidebar";
import Instagram from "./images/ins.png";
import Facebook from "./images/face.png";
import Youtube from "./images/yt.png";
import Linkedin from "./images/linked.png";
import { DivisorLogin } from "./DivisorLogin";
import { Button } from "react-bootstrap";

const Nav = styled.div`
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav``;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {
  const ref = useRef();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDrop, setIsDrop] = useState(false);
  const [user, setUser] = useState(false);
  const [admin, setAdmin] = useState(false);

  useOnClickOutside(ref, () => setModalOpen(false));
  const stop = (e) => {
    // setIsDrop(false);
    e.stopPropagation();
  };
  const superSidebar = (e) => {
    setModalOpen(true);
  };

 const Logout = () => {
  document.body.click();
  localStorage.clear();
  window.location.reload();
 }
 
 
  return (
    <div className="" style={{ backgroundColor: "#fff" }}>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav className="nav">
          {isModalOpen === false ? (
            <FaIcons.FaBars
              onClick={(e) => superSidebar(e)}
              className="hamburger"
            />
          ) : (
            <FaIcons.FaAlignLeft className="hamburger" />
          )}
          <div className="logo-decathlon">
            <img
              onClick={() => (window.location.href = "/")}
              src={LogoDecathlon}
              alt="logo"
            />
          </div>
          <div className="right">
            <NavIcon to="/team">
              <img src={logo} alt="logo" />
              <span className="span pl-04 va-b">TRABAJA EN DECATHLON</span>
            </NavIcon>
            <NavIcon as={"div"}>
              {JSON.parse(localStorage.getItem("user")) &&
              JSON.parse(localStorage.getItem("user")).isAdmin === true ? (
                <>
                  <div className={`dropdown ` + isDrop && " shows"}>
                    <Link
                      to="/cms/auth/admin-vacantes"
                      className="btn btn-admin ml-4"
                    >
                      ADMINISTRACIÓN
                    </Link>

                    {/* <Link className="btn-perfil pt-2  pb-2" to="/cms/auth/admin-vacantes">Administración</Link> */}
                  </div>
                </>
              ) : (
                <>
                  {user ? (
                    <>
                      <img className="pl-4 pr-3" src={User} alt="logo" />
                      <div className={`dropdown ` + isDrop && " shows"}>
                        <span
                          className="span dropdown-toggle va-t "
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span>PERFIL</span>
                        </span>
                        <div
                          className="dropdown-menu dropdown-menu-right drpdw"
                          style={{ marginTop: "1em", width: "190px" }}
                          aria-labelledby="dropdownMenuButton"
                          onClick={stop}
                        >
                          <div className="col-lg-12">
                            <form autoComplete="off">
                            <div className="container-fluid  text-center  pt-2  mb-2">
                                <Link
                                  className="btn-perfil mb-5 "
                                  to="/status"
                                  onClick={() => {
                                    document.body.click();
                                  }}
                                >
                                  Perfil
                                </Link>
                                </div>
                                <div className="container-fluid  text-center   mb-2">
                                <Link
                                  className="btn-perfil "
                                  to="/"
                                  onClick={() => {
                                    Logout() 
                                  }}
                                >
                                  Cerrar sesión
                                </Link>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <img className="pl-4 pr-3" src={User} alt="logo" />
                      <div className="dropdown">
                        <span
                          className="span dropdown-toggle va-t "
                          id="dropdownMenuButton"
                          // data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span onClick={() => setIsDrop(!isDrop)}>PERFIL</span>
                        </span>
                        <div
                          className={
                            isDrop === true
                              ? "  dropdown-menu dropdown-menu-right drpdw animate__animated animate__fadeIn animate__faster show"
                              : "  dropdown-menu dropdown-menu-right drpdw animate__animated animate__fadeIn animate__faster"
                          }
                          style={
                            window.matchMedia("(min-width: 450px)").matches
                              ? {
                                  marginTop: "1em",
                                  width: "400px",
                                }
                              : {
                                  marginTop: "1em",
                                  width: "350px",
                                }
                          }
                          aria-labelledby="dropdownMenuButton"
                          onClick={stop}
                        >
                          <DivisorLogin
                            setIsDrop={setIsDrop}
                            setUser={setUser}
                            setAdmin={setAdmin}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </NavIcon>
          </div>
        </Nav>
        {isModalOpen ? (
          <div ref={ref}>
            <SidebarNav className="font- sidebarNavs ">
              <SidebarWrap>
                {/* <NavIcon to="#">
                  <AiIcons.AiOutlineClose onClick={() => setModalOpen(false)} />
                </NavIcon> */}
                <br />
                {SidebarData.map((item, index) => {
                  return (
                    <SubMenu
                      setModalOpen={setModalOpen}
                      isModalOpen={isModalOpen}
                      item={item}
                      key={index}
                    />
                  );
                })}
                <span className="Icenter mt-4">
                  <a href="https://www.instagram.com/decathlon_mx/?hl=en">
                    <img src={Instagram} alt="Instagram" className="icon2" />
                  </a>
                  <a href="https://www.facebook.com/DecathlonMexico/">
                    <img src={Facebook} alt="Facebook" className="icon2" />
                  </a>
                  <a href="https://www.youtube.com/channel/UCLBtITHHNjaevRunUk3sT0g">
                    <img src={Youtube} alt="Youtube" className="icon2" />
                  </a>
                  <a href="https://www.linkedin.com/company/decathlon-mexico/mycompany/">
                    <img src={Linkedin} alt="Linkedin" className="icon2" />
                  </a>
                </span>
              </SidebarWrap>
            </SidebarNav>
          </div>
        ) : (
          <button style={{ display: "none" }}></button>
        )}
      </IconContext.Provider>
    </div>
  );
};

export default Sidebar;
