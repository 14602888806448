import styled from "styled-components";
import { Link } from "react-router-dom";
import SomosDeporte from "../components/images/somos-deporte.png";
import Accesible from "../components/images/accesible.png";
import Digital from "../components/images/digital.png";
import Diverso from "../components/images/diverso.png";
import Sustentable from "../components/images/sustentable.png";
import { IoIosArrowForward } from "react-icons/io";
import video from "../components/images/bannerVidHome.mp4";
import Typed from "react-typed";

const Nav = styled.div`
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const Home = () => {
  return (
    <div className="back" >
      <div className=" banner ">
        <video className="videoTag animate__animated animate__fadeIn" autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
        <div className="txts">
          <div className="txt-img-l pl-1 animate__animated animate__fadeInUp animate__slow">
            VIVE EL DEPORTE <br />
            DE UNA FORMA <br />
            DIFERENTE
          </div>
          <div className="txt-img-r  pr-5  animate__animated animate__fadeInDown animate__slow">
            <div className="flex-nw">
              <div className="pl-02 pb-1  val-1">
                <Typed
                  strings={[
                    "ERES DEPORTE?",
                    "ERES RUNNER?",
                    "ERES TENISTA?",
                    "ERES FUTBOLISTA?",
                    "ERES DEPORTE?",
                    "ERES SKATER?",
                    "ERES CICLISTA?",
                  ]}
                  typeSpeed={90}
                  backSpeed={100}
                  loop
                />
              </div>
              <Link className="btn-b   pl-02 " to="/team">
                TRABAJA EN DECATHLON <IoIosArrowForward className="" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <Nav className="somos-deporte h100">
          <img src={SomosDeporte} alt="somos-deporte" className="img-nav pr-1" />
          <Link to="/accesible" className="cont-img">
            <img src={Accesible} alt="accesible" className="img-nav-1" />
            <span style={{ padding: "5px 0" }}>ACCESIBLE</span>
          </Link>
          <Link to="/sustentable" className="cont-img">
            <img src={Sustentable} alt="sustentable" className="img-nav-1" />
            <span style={{ padding: "5px 0" }}>SUSTENTABLE</span>
          </Link>
          <Link to="/digital" className="cont-img">
            <img src={Digital} alt="digital" className="img-nav-1" />
            <span style={{ padding: "5px 10px" }}>DIGITAL</span>
          </Link>
          <Link to="/diverso" className="cont-img">
            <img src={Diverso} alt="diverso" className="img-nav-1" />
            <span style={{ padding: "5px 10px" }}>DIVERSO</span>
          </Link>
          
        </Nav> */}
    </div>
  );
};

export default Home;
