import React from "react";
import { Route, Redirect } from "react-router-dom";
import { PropTypes } from "prop-types";

const PrivateRoute = ({ isAutenticated, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={(props) =>
        isAutenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default PrivateRoute;

PrivateRoute.propTipes = {
  isAutenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};
