import React from "react";
import img1 from "../components/images/picto-03.png";
import img2 from "../components/images/picto-01.png";
import img3 from "../components/images/picto-02.png";
import eco from "../components/images/eco-design.gif";
import verde from '../components/images/verdeCircle.png'
const Sustentable = () => {
  return (
    <div className="Container-sust">
      <div className="bg-banner-sust">
        <div className="video-sust">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/SczY5lgVvS8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="item-1sust">
          <span className="sust-title">
            ¿QUÉ HACEMOS EN DECATHLON POR LA SUSTENTABILIDAD?
          </span>
          <span className="sust-desc">
            <br />
            En Decathlon, somos deportistas responsables con el cuidado de
            nuestro planeta. Entendemos el medio natural como un escenario
            esencial para la práctica deportiva.Por eso, actuamos para proteger
            nuestro terreno de juego y generar un impacto positivo.{" "}
          </span>
        </div>
      </div>
      <div className="mats">
        <div className="item-mats">
          <img className="icon-res" src={img1} alt="" />

          <span className="titl-mats">
            {" "}
            <b>MENOR</b>
          </span>

          <span className="desc-mats">CONSUMO DE AGUA Y ENERGÍA</span>
        </div>
        <div className="item-mats">
          <img className="icon-res" src={img2} alt="" />

          <span className="titl-mats">
            {" "}
            <b>MATERIALES</b>
          </span>

          <span className="desc-mats">MENOS CONTAMINANTES</span>
        </div>
        <div className="item-mats">
          <img className="icon-res" src={img3} alt="" />

          <span className="titl-mats">
            {" "}
            <b>MATERIAS </b>
          </span>

          <span className="desc-mats">PRIMAS RECICLADAS</span>
        </div>
      </div>
      <div className="centerIframe">
        <div className="iframe-container">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/SczY5lgVvS8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="container-eco">
        <div className="gif-eco">
          <img width="100%" src={eco} alt="" />
        </div>

        <div className="desc-eco">
          <p>
            Desde el eco-diseño y la producción de nuestras materias primas
            hasta el final de la vida útil de nuestros productos y nuestras
            estrategias de segunda vida.
          </p>
          <p>
            <br />
            Pretendemos construir una comunidad para hacer llegar el deporte al
            mayor número de personas y así contribuir a una sociedad más
            saludable y sustentable.
          </p>
        </div>
      </div>
      <div className="pensando-img">
        <img  width="100%" src={verde} alt="" />
      </div>
      <div className="pensando-txt">
        Diseñamos pensando en el medio ambiente
      </div>
      
    </div>
  );
};

export default Sustentable;
