import React from 'react';
import ReactDOM from 'react-dom';
import './App.scss';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-N59F3N7'
}
TagManager.initialize(tagManagerArgs)
ReactGA.initialize("G-JKLTE0SPJH");
ReactDOM.render(
<React.StrictMode>
<BrowserRouter>
  <App />
</BrowserRouter>

</React.StrictMode>,
document.getElementById('root')
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
reportWebVitals(SendAnalytics);
