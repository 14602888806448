import React, { useEffect, useRef } from "react";
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility';

const style = {
  width: "100%",
  height: "600px"
};

function Map({ markerPosition, onMarkerClick, onMapClick, centerPosition, zoomLevel }) {
    const mapRef = useRef(null);
    const markerLayerRef = useRef(null);

    useEffect(() => {
        if (!mapRef.current) {
            mapRef.current = L.map("map", {
                center: [19.4311768, -99.2047097],
                zoom: 5,
                layers: [
                    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                        attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    })
                ]
            });

            markerLayerRef.current = L.layerGroup().addTo(mapRef.current);

            mapRef.current.on('click', (event) => {
                if (onMapClick) {
                    onMapClick(event.latlng);
                }
            });
        }

        if (centerPosition) {
            mapRef.current.setView(centerPosition, zoomLevel);
        }

    }, [centerPosition, zoomLevel, onMapClick]);

    useEffect(() => {
        if (mapRef.current && markerLayerRef.current) {
            markerLayerRef.current.clearLayers();

            const locationCount = markerPosition.reduce((acc, loc) => {
                const key = `${loc.lat},${loc.lng}`;
                if (!acc[key]) {
                    acc[key] = { ...loc, count: 1 };
                } else {
                    acc[key].count += 1;
                }
                return acc;
            }, {});

            Object.values(locationCount).forEach(loc => {
                L.marker([loc.lat, loc.lng], {
                    icon: new L.divIcon({
                        html: `
                            <svg width="100" height="100">
                                <circle cx="50" cy="50" r="15" fill="#00689d" />
                                <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="16px" font-family="Arial" dy=".3em">${loc.count}</text>
                            </svg>
                        `,
                        className: "",
                        iconSize: [24, 40],
                        iconAnchor: [12, 40],
                    })
                })
                .addTo(markerLayerRef.current)
                .on('click', () => onMarkerClick(loc));
            });
        }
    }, [markerPosition, onMarkerClick]);

    return <div id="map" style={style} />;
}

export default Map;
