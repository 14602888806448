import { FormNext } from "grommet-icons";
import React, { useState } from "react";
import { useRef } from "react";
import { Col, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { LoaderChico } from "../components/LoaderChico";
import { BACKEND } from "../components/backend";

const Recuperar = () => {
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    email: "",
  });
  const [captchaValido, setCaptchaValido] = useState(null);
  const [loader, setLoader] = useState(null);
  const [res, setRes] = useState(null);
  const [success, setSuccess] = useState(null);
  const [msg, setMsg] = useState(null);

  const captcha = useRef(null);

  const exp = {
    password: /^[a-zA-Z0-9\_\-]{6,12}$/,
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };
  let lower;
  const handleChange = (e) => {
    const { name, value } = e.target;
    lower = value.toLowerCase();
    setForm({ ...form, [name]: lower.trim() });
  };
  const handleBlur = (e) => {
    ///// SETEO ERRORES

    switch (e.target.name) {
      case "email":
        // email
        if (exp.correo.test(form.email.trim())) {
          setErrors({ ...errors, email: false });
        }
        if (!form.email.trim()) {
          setErrors({ ...errors, email: true });
        } else if (!exp.correo.test(form.email.trim())) {
          setErrors({ ...errors, email: true });
        }
        break;
      default:
        break;
    }
  };
  const handleCaptcha = () => {
    if (captcha.current.getValue()) {
      setErrors({ ...errors, captcha: false });
    }
  };
  /////// SUBMIT
  const baseUrl = BACKEND + "api/reset_password";

  const fetchData = async () => {
    try {
      const resp = await axios({
        url: baseUrl,
        method: "POST",
        data: form,
      });
      setRes(resp);
      if (resp.data.success === 1) {
        setTimeout(() => {
          setLoader(false);
          setSuccess(true);
          setCaptchaValido(true);
        }, 2500);
        setCaptchaValido(false);
        setMsg(resp.data.message);
      }
      if (resp.data.success === 0) {
        setTimeout(() => {
          setLoader(false);
          setSuccess(false);
          setCaptchaValido(true);
        }, 2500);
        setMsg(resp.data.message);
        setErrors({ ...errors, err: true });
        setTimeout(() => {
          setSuccess(null);
          setErrors({ ...errors, err: false });
          setCaptchaValido(false);
        }, 6000);
      }
    } catch (error) {
    } finally {
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (captcha.current.getValue()) {
      //  setLoader(true);
      // setCaptchaValido(true);
      if (errors.email === false) {
        fetchData();
        setLoader(true);
      } else if (form.email === 0 || form.email === "") {
        setErrors({ ...errors, email: true });
      }
    } else {
      // setLoader(true);
      //  setCaptchaValido(true);
      setErrors({ ...errors, captcha: true });
    }
  };
  return (
    <>
      <div className="container animate__animated animate__fadeIn animate__slow">
        {!loader ? (
          !captchaValido && (
            <>
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <br />
                  <br />
                  <br />
                  <span className="forgot-tit ">RESTABLECER CONTRASEÑA</span>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-md-8 col-sm-12 text-center  ">
                  <br />
                  <br />
                  <span className="forgot-desc ">
                    Introduce tu <b> dirección de correo electrónico </b>que
                    usaste para registrarte. Te enviaremos un correo electrónico
                    con tu nombre de ususario y un enlace para restablecer tu
                    contraseña.
                  </span>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className=" col-11 col-sm-8 col-lg-5 mt-5">
                  <Form onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridnombre">
                        <Form.Label className="formTitl">
                          Escribe tu correo electrónico
                        </Form.Label>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridnombre">
                        <Form.Control
                          type="text"
                          className="stylePlace stylePlacer"
                          name="email"
                          style={{
                            textTransform: "lowercase",
                            borderColor: errors.email == true && "#ff0000",
                          }}
                          values={form.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                        />
                        {errors.email == true && (
                          <p className="text-danger text-errors">
                            {" "}
                            El campo email es requerido.
                          </p>
                        )}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group>
                        <ReCAPTCHA
                          ref={captcha}
                          sitekey="6LdSd-AnAAAAAAe-99f6dnAuoRfXU76qC9QB3LXI"
                          onChange={handleCaptcha}
                        />
                        {errors.captcha == true && (
                          <p className="text-danger text-errors">
                            {" "}
                            Valide el captcha.
                          </p>
                        )}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row className="justify-content-center mt-4 mb-5">
                      <button
                        className="forgot-enviar animate__animated animate__fadeIn text-center text-white "
                        type="submit"
                      >
                        <span className="label ">
                          ENVIAR &nbsp; <FormNext color="white" />
                        </span>
                      </button>
                    </Form.Row>
                  </Form>
                </div>
              </div>
            </>
          )
        ) : (
          <div className="row justify-content-center my-5 py-5">
            <div className="col-12 text-center my-5">
              <br />
              <br />
              <br />
              <LoaderChico />
            </div>
          </div>
        )}
        {success && (
          <div className="row justify-content-center my-5 py-5">
            <div className="col-12 text-center my-5">
              <br />
              <br />
              <br />
              <span className="forgot-crear ">
                Gracias <br />
                <br />
              </span>
              <span style={{ fontSize: "1.3em", color: "gray" }}>
                Se envió un email con las instrucciones para restablecer su
                contraseña.
                <br />
              </span>
              <span style={{ fontSize: "1.3em", color: "gray" }}>{msg}</span>
            </div>
          </div>
        )}
        {success === false && (
          <Form.Row>
            <br />
            <br />
            <br />
            <button className="EnviarError animate__animated animate__fadeIn my-5">
              <span className="label">
                Ocurrio un error al enviar los datos.
                <br />
                {msg}
              </span>
            </button>
          </Form.Row>
        )}
      </div>
    </>
  );
};

export default Recuperar;
