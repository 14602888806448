import React from "react";
import { useContext } from "react";
import { AuthContext } from "../auth/AuthContext";
import { types } from "../types/types";

const LoginAdmin = ({history}) => {
  const { dispatch } = useContext(AuthContext);
  const handleLogin = () => {

    // window.location.href = BACKEND+'api/authenticate';
    
    dispatch({
      type: types.login,
      payload:{
        name: 'Carlos',
      }
    });
    // window.location.href = 'http://localhost:3000/#/cms/auth/crear-vacante?authorization=123&refresh=1';
      history.replace('/cms/auth/logger?authorization=123&refresh=1');
  }
  return (
    <div>
      <button type="button" className="btn btn-primary" 
      onClick={handleLogin}>
        Login
      </button>
    </div>
  );
};

export default LoginAdmin;
