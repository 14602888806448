import { useReducer, useEffect } from "react";
import "./styles/App.css";
import Sidebar from "./components/Sidebar";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Perfil from "./pages/Perfil";
import Accesible from "./pages/Accesible";
import Sustentable from "./pages/Sustentable";
import Digital from "./pages/Digital";
import Diverso from "./pages/Diverso";
import Innovacion from "./pages/Innovacion";
import Cultura from "./pages/Cultura";
import Footer from "./components/footer/Footer";
import Carrera from "./pages/Carrera";
import Decatips from "./pages/Decatips";
import DescOfert from "./pages/DescOferta";
import { AuthContext } from "./auth/AuthContext";
import { authReducer } from "./auth/authReducer";
import AppRouter from "./components/Routes/AppRouter";
import Recuperar from "./pages/Recuperar";
import Restablecer from "./pages/Restablecer";
import NotFound from './NotFound';
import "animate.css";

const init = () => {
  return JSON.parse(localStorage.getItem("user")) || { logged: false };
};
function App() {
  const [user, dispatch] = useReducer(authReducer, {}, init);
  
  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user])
  return (
    <div className="content">
      <Router>
          <AuthContext.Provider value={{ user, dispatch }}>
        <Sidebar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/team/:store_id?" exact component={Team} />
          <Route path="/perfil/:job_id?" exact component={Perfil} />
          <Route path="/Accesible" exact component={Accesible} />
          <Route path="/sustentable" exact component={Sustentable} />
          <Route path="/digital" exact component={Digital} />
          <Route path="/diverso" exact component={Diverso} />
          <Route path="/innovacion" exact component={Innovacion} />
          <Route path="/cultura" exact component={Cultura} />
          <Route path="/carrera" exact component={Carrera} />
          <Route path="/decatips" exact component={Decatips} />
          <Route path="/recuperar/:tkn_restore?" exact component={Recuperar} />
          <Route path="/restablecer" exact component={Restablecer} />
          {/* <Route path="/status" exact component={Status} /> */}
          <Route path="/desc_ofert/:job_id?" exact component={DescOfert} />
          {/* <Route component={NotFound} /> */}
              <AppRouter   />

        </Switch>
          </AuthContext.Provider>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
