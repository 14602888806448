import React, { useContext } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthContext';
import AdminVacantes from '../cms/auth/admin-vacantes';
import CrearVacante from "../cms/auth/crear-vacante";
// import Cooptacion from '../cms/auth/cooptacion';
import BaseDatosCv from "../cms/baseDatosCv";
import Soporte from "../cms/auth/soporte";
import Dashboardh from "../cms/auth/dashboardh";
import Logger from '../cms/auth/logger';
import AdminStatus from '../cms/auth/admin-status';
const CmsRoutes = () => {
const user = useContext(AuthContext)
    return (
            <Switch>

            <Route path="/cms/auth/admin-vacantes"  component={AdminVacantes} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth/crear-vacante"  component={CrearVacante} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth/base-datos-cv"  component={BaseDatosCv} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth/soporte"  component={Soporte} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth/dashboardh"  component={Dashboardh} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth/admin-status/:id?"  component={AdminStatus} isAutenticated={ user.isAdmin}  />
            <Route path="/cms/auth:authization?:refresh?"  component={Logger} isAutenticated={ user.isAdmin}  />
            {/* <Route path="/cms/auth/cooptacion"  component={Cooptacion} /> */}
            <Redirect to="/cms/auth/crear-vacante" />
            </Switch>
    )
}

export default CmsRoutes
