import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import video from "../components/images/rectangle.png";
import casa from "../components/images/casa.png";

function Innovacion() {
    return (
        <>
            <div className="innovacion">
                <Row>
                    <p className="title-inn">LA INNOVACIÓN FORMA PARTE DE NUESTRO ADN</p> 
                    <br />
                    <p className="title-inn--l">¿Quieres saber más?  </p>
                    <p className="title-inn--m">¡Conoce nuestra visión para los dos próximos años!</p>
                </Row>
                <Row >
                    <img src={video} alt="" className="inn-video"/>
                </Row>
                <Row>
                    <Col style={{padding: "95px 0"}} xs={12}>
                    <p className="inn-p">Vivimos en un entorno en constante cambio y las necesidades de los deportistas no son una excepción.<br /><br />
                        En Decathlon innovamos para adaptarnos a las necesidades de nuestros usuarios.<br /><br />
                        La Innovación forma parte de nuestro ADN. Desde el diseño, hasta la venta en tienda pasando por la producción y la logística. </p>
                    </Col>
                    <Col xs={11}>
                        <img src={casa} alt="" className="inn-img" />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Innovacion;

