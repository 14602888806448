import React from "react";
import Form from "../components/form";

const Team = (jobId) => {
  return (
    <>
      <Form jobId={jobId} className="animate__animated animate__fadeIn animate__slow"/>
    </>
  );
};

export default Team;
